import { TargetPromotionRequest } from '../../common/models/target-promotion-request';
import { PlayerActivityContent } from './player-activity-content';
import { PlayerActivityMetaData } from './player-activity-metadata';

export class PlayerActivityDetails {
    public playerActivityContent: PlayerActivityContent;
    public playerActivityMetaData: PlayerActivityMetaData;
}

export class PlayerActivityRequest extends TargetPromotionRequest {
    isAutoOptin: boolean;
    constructor(promoId: string, requestType: string, isAutoOptin: boolean = false) {
        super();
        this.promoId = promoId;
        this.requestType = requestType;
        this.isAutoOptin = isAutoOptin;
    }
}
