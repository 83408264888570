import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { PromotionsResourceService } from './promotions-resource.service';

@Injectable()
export class PromotionDetailsResolver implements Resolve<any> {
    constructor(private resource: PromotionsResourceService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.resource.getSingle(route.paramMap.get('product'), route.paramMap.get('folderId'));
    }
}
