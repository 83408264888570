<ng-container *ngrxLet="vm$ as vm">
    @if (vm) {
        @if (showOfferType) {
            <div class="offer-badge txt-s">
                @if (vm.badgesDot && offer.offerMetadata?.isNewOffer) {
                    <span class="badge-separation-dot">{{ vm.badgesDot }}</span>
                }
                @if (!!offer.offerContent?.offerType?.text) {
                    <span
                        [ngClass]="
                            offer.offerContent?.offerType?.cssClass
                                ? offer.offerContent?.offerType?.cssClass
                                : 'badge-product badge-sitecore-promotions'
                        ">
                        {{ offer.offerContent?.offerType?.text }}
                    </span>
                }
            </div>
        }
    }
</ng-container>
