import { createAction, props } from '@ngrx/store';

import { PlayerActivityDetails, PlayerActivityRequest } from '../models/player-activity-details';

export const playerActivityInit = createAction('[PlayerActivity Promotion] Init');
export const playerActivityDestroyed = createAction('[PlayerActivity Promotion] Destroyed');
export const loadPlayerActivityData = createAction('[PlayerActivity Promotion] LoadData', props<PlayerActivityRequest>());
export const loadPlayerActivityData_Success = createAction('[PlayerActivity Promotion] Load_Success', props<PlayerActivityDetails>());

export const loadPlayerActivityOptinData_Success = createAction('[PlayerActivity Promotion] Optin Load_Success', props<PlayerActivityDetails>());
