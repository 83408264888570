import { ContentItem, ContentLink } from '@frontend/vanilla/core';

import { ResultsPrize } from '../../common/models/results/results-prize';
import { TargetPromotionContent } from '../../common/models/target-promotion-content';
import { EdsMyProgress } from './eds-my-progress';

export class EdsContent extends TargetPromotionContent {
    edsMyProgress: EdsMyProgress;
    isMultiRewards: boolean;
    pendingPrize: ResultsPrize;
    contactUsCta: ContentLink;
    titleImage: ContentItem;
}

export enum Status {
    Offered,
    Claimed,
    NotOffered,
}
