import { ElementRef, Injectable } from '@angular/core';

import { NativeAppService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';

import { TargetPromotionDetailConfiguration } from '../../../../../client-configs/target-promotion-detail-config';
import { ScrollingEventService } from '../../events/scrolling-event.service';
import { LeaderboardRankingRequest } from '../../models/ranking/leaderboard-ranking-request';
import { RankingModel, StickyRow } from '../../models/ranking/ranking-models';
import { getLeaderboardRankingScrollDownData, getLeaderboardRankingScrollUpData } from '../store/leaderboard-ranking.actions';

@Injectable()
export class LeaderboardRankingScrollService {
    stickyRow: StickyRow = new StickyRow();
    rankingModel: RankingModel;
    leaderboardRankingRequest: LeaderboardRankingRequest;

    private _titleHeight: number = 56; /* px */
    private _navBarHeight: number = 64; /* px */
    private _windowHeight: number = window.innerHeight;

    constructor(
        public scrollingEventService: ScrollingEventService,
        private store: Store,
        private targetPromotionDetailConfig: TargetPromotionDetailConfiguration,
        private nativeAppService: NativeAppService,
    ) {
        this.rankingModel = new RankingModel();
        this._titleHeight = this.nativeAppService.isNative || this.nativeAppService.isDownloadClient ? 0 : this._titleHeight;
    }

    handleStickyScrolling(stickyRowElement: ElementRef, rankingScroll: ElementRef): StickyRow {
        this.checkIfScrollUpOrDown(rankingScroll);
        if (stickyRowElement) {
            if (!this.stickyRow.stickyElementHeight) {
                this.stickyRow.stickyElementHeight = stickyRowElement.nativeElement.getBoundingClientRect().height;
            }

            this.stickyRow.stickyElementPosition = stickyRowElement.nativeElement.getBoundingClientRect().top;
            if (!this.stickyRow.stickyBottom) {
                this.checkTopPosition();
            }
            if (!this.stickyRow.stickyTop) {
                this.checkBottomPosition();
            }
        }
        return this.stickyRow;
    }

    updateTopOffSet(rankingScroll: ElementRef) {
        this.rankingModel.topOffSet = rankingScroll?.nativeElement?.getBoundingClientRect()?.top;
    }

    loadLeaderboardRankingRequest(promoId: string) {
        this.leaderboardRankingRequest = new LeaderboardRankingRequest();
        this.leaderboardRankingRequest.promoId = promoId;
        this.leaderboardRankingRequest.requestType = 'ranking';
        return this.leaderboardRankingRequest;
    }

    private checkIfScrollUpOrDown(rankingScroll: ElementRef) {
        if (this.rankingModel.topOffSet) {
            if (this.rankingModel.isScrolled) {
                return;
            }
            const top = rankingScroll.nativeElement.getBoundingClientRect().top;
            if (this.rankingModel.topOffSet > top) {
                this.rankingModel.isScrollDown = true;
                this.onScrollDown();
            } else {
                this.onScrollUp();
                this.rankingModel.isScrollDown = false;
            }
        }
        this.updateTopOffSet(rankingScroll);
    }

    onScrollUp(minRank: number = 0, maxRank: number = 0) {
        if (this.leaderboardRankingRequest.minRank < 1) return;

        this.leaderboardRankingRequest.maxRank = maxRank > 0 ? maxRank : this.rankingModel.globalMinRank - 1;
        this.leaderboardRankingRequest.minRank =
            minRank > 0 ? minRank : this.leaderboardRankingRequest.maxRank - Number(this.targetPromotionDetailConfig.fetchRankingCount);

        this.rankingModel.isScrolled = false;
        if (this.leaderboardRankingRequest.maxRank >= 1) {
            this.rankingModel.isScrolled = true;
            const showSpinner = this.targetPromotionDetailConfig?.isLBRankingV2Enabled ? false : true;
            this.store.dispatch(getLeaderboardRankingScrollUpData({ leaderBoardRequest: this.leaderboardRankingRequest, showSpinner }));
        }
    }

    onScrollDown(minRank: number = 0, maxRank: number = 0) {
        if (this.rankingModel.downEmptyCallCount >= 2) {
            return;
        }

        this.leaderboardRankingRequest.minRank = minRank > 0 ? minRank : this.rankingModel.globalMaxRank + 1;
        this.leaderboardRankingRequest.maxRank =
            maxRank > 0 ? maxRank : this.leaderboardRankingRequest.minRank + Number(this.targetPromotionDetailConfig.fetchRankingCount);

        if (this.targetPromotionDetailConfig?.isLBRankingV2Enabled) {
            const maxRankValue = Number(this.targetPromotionDetailConfig.leaderBoardMaxRank);
            if (this.leaderboardRankingRequest.maxRank > maxRankValue) this.leaderboardRankingRequest.maxRank = maxRankValue;
        }

        if (
            this.leaderboardRankingRequest.minRank > 0 &&
            this.leaderboardRankingRequest.maxRank > 0 &&
            this.leaderboardRankingRequest.maxRank > this.leaderboardRankingRequest.minRank
        ) {
            this.rankingModel.isScrolled = true;
            const showSpinner = this.targetPromotionDetailConfig?.isLBRankingV2Enabled ? false : true;
            this.store.dispatch(getLeaderboardRankingScrollDownData({ leaderBoardRequest: this.leaderboardRankingRequest, showSpinner }));
        }
    }

    private checkTopPosition() {
        if (this.stickyRow.stickyElementPosition <= this._navBarHeight + this._titleHeight) {
            this.stickyRow.stickyTop = true;
            this.stickyRow.scrollInRow = false;
            this.stickyRow.isStickOnce = true;
        } else {
            if (this.stickyRow.isStickOnce) {
                this.stickyRow.scrollInRow = true;
            }
            this.stickyRow.stickyTop = false;
        }
        this.scrollingEventService.sendStickyTopStatus(this.stickyRow.stickyTop);
    }

    private checkBottomPosition() {
        if (this._windowHeight - this.stickyRow.stickyElementPosition - this.stickyRow.stickyElementHeight <= 72) {
            this.stickyRow.stickyBottom = true;
            this.stickyRow.scrollInRow = false;
            this.stickyRow.isStickOnce = false;
        } else {
            this.stickyRow.stickyBottom = false;
        }
    }
}
