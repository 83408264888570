import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prPromooffershistory', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: offersHistoryFactory,
})
export class OffersHistoryClientConfiguration extends LazyClientConfigBase {
    items: any[];
    itemName: any;
}

export function offersHistoryFactory(service: LazyClientConfigService) {
    return service.get(OffersHistoryClientConfiguration);
}
