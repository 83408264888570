<ng-container *ngrxLet="vm$ as vm">
    @if (vm) {
        <div class="offers-teaser-group">
            @if (vm.indexes?.length > 0 && (showTeaserGroupTitle || vm.isDesktop || offerCategory?.text)) {
                <div class="offers-teaser-group-header">
                    <div class="offers-teaser-group-title otgt-title">
                        @if (showBackArrow && vm.isDesktop) {
                            <a class="offers-teaser-group-back theme-arrow-left dh" (click)="processBackClick()" queryParamsHandling="merge"></a>
                        }
                        @if (!offersProductStyle?.textColour && !!offerCategory.productImage) {
                            <img
                                class="otgt-image"
                                [ngClass]="offerCategory.class"
                                [attr.src]="offerCategory.productImage.src"
                                [attr.alt]="offerCategory.productImage.alt"
                                [width]="offerCategory.productImage.width"
                                [height]="offerCategory.productImage.height" />
                        }
                        @if (offersProductStyle?.textColour) {
                            <div
                                class="otgt-image"
                                [style.alt]="offerCategory.productImage?.alt"
                                [style.width]="offerCategory.productImage?.width"
                                [style.height]="offerCategory.productImage?.height"
                                [style.background-color]="offersProductStyle?.textColour"
                                style="filter: invert(0);
                            -webkit-mask: url('{{ offerCategory.productImage?.src }}') no-repeat center / contain;"></div>
                        }
                        <div class="otgt-wrapper">
                            <div class="otgt-header" [style.color]="offersProductStyle?.textColour">
                                {{ offerCategory?.categoryTitle }}
                            </div>
                            @if (offerCategory?.subtitle) {
                                <div class="otgt-subheader" [style.color]="offersProductStyle?.textColour">
                                    {{ offerCategory?.subtitle }}
                                </div>
                            }
                        </div>
                    </div>
                    @if (offerCategory?.text && userService.isAuthenticated) {
                        <div [vnDynamicHtml]="offerCategory.text"></div>
                    }
                    @if (showSeeAllLink && !isFreeOffer && vm.indexes.length > seeAllLinkItemLength) {
                        <div class="offers-teaser-group-see-all">
                            <a
                                [attr.href]="offerCategory.viewAllLink?.url"
                                [style.color]="offersProductStyle?.textColour"
                                [ngClass]="[viewAllCtaClass]"
                                (click)="processViewAllClick(); $event.preventDefault()"
                                >{{ vm.clientContentMessages?.ShowAll }} ({{ vm.indexes.length }})
                            </a>
                        </div>
                    }
                </div>
            }
            @if (offerDetailsList?.length > 0 && vm.indexes?.length > 0) {
                <div class="offers-teaser-group-items vn-carousel">
                    @if (swiper?.navigation && arrows && !isMyGala) {
                        <i
                            class="theme-left carousel-previous"
                            role="button"
                            aria-label="Previous slide"
                            (click)="onSlidePrev()"
                            [ngClass]="swiper?.isBeginning ? 'swiper-button-disabled' : ''"></i>
                        <i
                            class="theme-right carousel-next"
                            role="button"
                            aria-label="Next slide"
                            (click)="onSlideNext()"
                            [ngClass]="swiper?.isEnd ? 'swiper-button-disabled' : ''"></i>
                    }
                    @if (myGalaArrows && isMyGala) {
                        <div
                            [ngClass]="product"
                            role="button"
                            class="swiper-button-prev theme-left"
                            slot="container-end"
                            (click)="trackPrevNextEvent('left')"></div>
                        <div
                            [ngClass]="product"
                            role="button"
                            class="swiper-button-next theme-right"
                            slot="container-end"
                            (click)="trackPrevNextEvent('right')"></div>
                    }
                    @if (showLhSlider) {
                        <div #swiperContainer class="swiper">
                            <div class="swiper-wrapper">
                                @for (idx of vm.indexes; track trackByKey(idx); let i = $index) {
                                    <div class="swiper-slide">
                                        <div
                                            [class]="
                                                !vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile
                                                    ? 'offers-teaser-wrapper-multi'
                                                    : 'offers-teaser-wrapper-single'
                                            ">
                                            <offers-teaser
                                                class="offers-teaser-wrapper"
                                                [offer]="offerDetailsList[idx]"
                                                [product]="product"
                                                [groupedOffersCount]="offerDetailsList[idx]?.offerMetadata | groupedOffersCount: offerCategory"
                                                [teaserNumber]="i + 1"
                                                [isMyGala]="isMyGala">
                                            </offers-teaser>
                                            @if (!vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile) {
                                                <span class="offers-teaser-wrapper-multi-list"></span>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            @if (isMyGala) {
                                <span [ngClass]="product" class="swiper-pagination d-block" slot="container-end"></span>
                            }
                        </div>
                    }
                    @if (!showLhSlider) {
                        <div class="row offer-teaser-items-nocarousel">
                            @for (idx of vm.indexes; track trackByKey(idx); let i = $index) {
                                <div
                                    [class]="
                                        !vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile
                                            ? 'offers-teaser-wrapper-multi'
                                            : 'offers-teaser-wrapper-single'
                                    ">
                                    <offers-teaser
                                        class="offers-teaser-wrapper col-12 col-sm-6 col-md-4"
                                        [ngClass]="vm.carouselItemCssClass"
                                        [offer]="offerDetailsList[idx]"
                                        [product]="product"
                                        [groupedOffersCount]="offerDetailsList[idx]?.offerMetadata | groupedOffersCount: offerCategory"
                                        [teaserNumber]="i + 1"></offers-teaser>
                                    @if (!vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile) {
                                        <span class="offers-teaser-wrapper-multi-list"></span>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }
</ng-container>
