import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { ContentService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OptInDetails } from '../../common/models/opt-in-details';
import { EdsClaimRequest } from '../eds-models/eds-claim-request';
import { EdsClaimResponse } from '../eds-models/eds-claim-response';
import { EdsDetails } from '../eds-models/eds-details';
import { EdsProgressStatus } from '../eds-models/eds-enum';
import { EdsRequest } from '../eds-models/eds-request';

@Injectable({
    providedIn: 'root',
})
export class EdsService {
    edsDetails$ = new BehaviorSubject({} as EdsDetails);
    edsClientContent$ = new BehaviorSubject<any>({});
    edsProgressStatus: typeof EdsProgressStatus = EdsProgressStatus;

    constructor(
        private apiService: ApiService,
        private contentService: ContentService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getEdsDetails(edsRequest: EdsRequest): Observable<EdsDetails> {
        const edsDetails$ = this.apiService.get('edspromo', null, {
            params: edsRequest,
            showSpinner: true,
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(edsDetails$);
    }

    optedIn(promoId: string): Observable<OptInDetails> {
        const request = new EdsRequest(promoId, 'optin');
        return this.apiService.get('edspromo', null, {
            params: request,
        });
    }

    isEventStarted(edsProgressStatus: EdsProgressStatus): boolean {
        return edsProgressStatus?.toLowerCase() !== this.edsProgressStatus?.YetToStart?.toLowerCase();
    }

    isEventCompleted(edsProgressStatus: EdsProgressStatus): boolean {
        return edsProgressStatus?.toLowerCase() === this.edsProgressStatus?.Completed?.toLowerCase();
    }

    get getContent() {
        return this.edsClientContent$.asObservable();
    }

    loadContent() {
        this.contentService.getJsonFiltered('Promo/PromoHub/DetailPagesContent/Eds/EdsDataPointContent').subscribe((data) => {
            this.edsClientContent$.next(data);
        });
    }

    claimEdsReward(cliamRequest: EdsClaimRequest): Observable<EdsClaimResponse> {
        return this.apiService.post('edsclaimrequest', cliamRequest, {
            params: cliamRequest,
        });
    }
}
