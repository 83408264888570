import { createAction, props } from '@ngrx/store';

import { EdsDetails } from '../eds-models/eds-details';
import { EdsRequest } from '../eds-models/eds-request';

export const edsInit = createAction('[Eds Promotion] Init');
export const edsDestroyed = createAction('[Eds Promotion] Destroyed');
export const loadEdsData = createAction('[Eds Promotion] LoadData', props<EdsRequest>());
export const loadEdsData_Success = createAction('[Eds Promotion] Load_Success', props<EdsDetails>());
export const loadToastr = createAction('[Eds Promotion] LoadToastr', props<EdsDetails>());
