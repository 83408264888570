import { ElementRef, Injectable } from '@angular/core';

import { TargetPromotionDetailConfiguration } from '../../../../../client-configs/target-promotion-detail-config';
import { PlayerRankDetails } from '../../models/ranking/ranking-models';
import { LeaderboardRankingScrollService } from '../leaderboard-ranking-service/leaderboard-ranking-scroll.service';

@Injectable()
export class LeaderboardRankingHelperService {
    constructor(
        private leaderboardRankingScrollService: LeaderboardRankingScrollService,
        private targetPromotionDetailConfig: TargetPromotionDetailConfiguration,
    ) {}

    loadRankingDetails(
        isNewRankingListEmpty: boolean,
        leaderboardDetails: PlayerRankDetails[],
        rankingScroll: ElementRef,
        currentUserRank: number = 0,
    ) {
        if (!isNewRankingListEmpty) {
            this.leaderboardRankingScrollService.rankingModel.isScrolled = false;
            this.leaderboardRankingScrollService.updateTopOffSet(rankingScroll);
            this.loadGlobalRanks(leaderboardDetails, currentUserRank);
            this.checkIfThereAreAwardsWithRanks(leaderboardDetails);
        } else {
            this.emptyRankingList();
        }
    }

    private emptyRankingList() {
        if (this.leaderboardRankingScrollService.rankingModel.downEmptyCallCount < 2) {
            this.leaderboardRankingScrollService.rankingModel.isScrolled = false;
        }

        if (this.leaderboardRankingScrollService.rankingModel.isScrollDown) {
            this.leaderboardRankingScrollService.rankingModel.downEmptyCallCount++;
        }
    }

    private checkIfThereAreAwardsWithRanks(leaderboardDetails: PlayerRankDetails[]) {
        if (!this.targetPromotionDetailConfig?.isLBRankingV2Enabled) {
            this.leaderboardRankingScrollService.rankingModel.isThreeColumn = true;
            for (const playerRankDetails of leaderboardDetails) {
                if (playerRankDetails.award) {
                    this.leaderboardRankingScrollService.rankingModel.isThreeColumn = false;
                    break;
                }
            }
        }
    }

    private loadGlobalRanks(leaderboardDetails: PlayerRankDetails[], currentUserRank: number) {
        this.leaderboardRankingScrollService.rankingModel.globalMinRank = !this.leaderboardRankingScrollService.rankingModel.isScrollDown
            ? leaderboardDetails[0].rank
            : this.leaderboardRankingScrollService.leaderboardRankingRequest.minRank ?? leaderboardDetails[0].rank;

        this.leaderboardRankingScrollService.rankingModel.globalMaxRank = !this.leaderboardRankingScrollService.rankingModel.isScrollDown
            ? leaderboardDetails[leaderboardDetails.length - 1].rank
            : this.leaderboardRankingScrollService.leaderboardRankingRequest.maxRank ?? leaderboardDetails[leaderboardDetails.length - 1].rank;
        if (
            this.targetPromotionDetailConfig?.isLBRankingV2Enabled &&
            currentUserRank > 0 &&
            currentUserRank <= +this.targetPromotionDetailConfig.leaderBoardMaxRank
        ) {
            const currentUserIndex = leaderboardDetails.findIndex((x) => x.rank == currentUserRank - 50);
            if (currentUserIndex >= 0)
                this.leaderboardRankingScrollService.rankingModel.globalMaxRank =
                    currentUserIndex + 1 >= currentUserRank - 50
                        ? leaderboardDetails[leaderboardDetails.length - 1].rank
                        : leaderboardDetails[currentUserIndex - 1]?.rank;
        }
    }
}
