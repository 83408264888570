export enum JourneyType {
    Sequential = 'sequential',
    Parallel = 'parallel',
    Mixed = 'mixed',
}

export enum EdsProgressStatus {
    YetToStart = 'YetToStart',
    InProgress = 'InProgress',
    Completed = 'Completed',
}

export enum CampaignStatus {
    toBeStarted = 'ToBeStarted',
    in_progress = 'Inprogress',
    completed = 'Completed',
}

export enum EventTypes {
    DEPOSIT = 'DEPOSIT',
    BINGO_WGR = 'BINGO_WGR',
    CASINO_WGR = 'CASINO_WGR',
    WIN_N_SPIN_HANDS = 'WIN_N_SPIN_HANDS',
    N_HANDS = 'N_HANDS',
    PLAY_N_SPIN_HANDS = 'PLAY_N_SPIN_HANDS',
    SPEC_HAND = 'SPEC_HAND',
    BET_SETTLEMENT = 'BET_SETTLEMENT',
    BET_PLACEMENT = 'BET_PLACEMENT',
    N_TOURNAMENTS = 'N_TOURNAMENTS',
    ITM_WIN = 'ITM_WIN',
    REWARD_ONLY = 'REWARD_ONLY',
    LOGIN = 'LOGIN',
    X_OPPONENTS = 'X_OPPONENTS',
    LINK_BETMGM_TO_MARRIOTT_ACCOUNT = 'MARRIOTT_LINKAGE',
}
