<div class="generic-modal-wrapper rewards_list_pop">
    @if (isDesktop) {
        <div class="generic-modal-title">
            <div class="header-ctrl-wrapper">
                <div class="header-ctrl d-flex justify-content-between align-items-center">
                    <div class="navbar-switch-logo brand-logo"></div>
                    <div (click)="onClosePopup(); webTrackingForCloseAndNotNow('close', 'select rewards popup')" class="header-ctrl-r">
                        <span class="ui-icon ui-icon-size-lg ui-close theme-ex"></span>
                    </div>
                </div>
            </div>
        </div>
    }
    @if (onClaimRewardFailure) {
        <div class="content-message error theme-error-i mb-3">
            <div class="pc-text pc-component">
                <span class="pc-richtext">{{ onClaimRewardFailureMessage }}</span>
                <span
                    ><a [href]="edsContent?.contactUsCta?.url">{{ edsContent?.contactUsCta?.text }}</a></span
                >
            </div>
            <div (click)="onCloseErrorBanner()"><span class="ui-icon ui-icon-size-md ui-close theme-ex"></span></div>
        </div>
    }

    <div class="generic-modal-content-wrapper">
        <div class="generic-modal-content">
            <div class="rewards_list_pop_header">
                <span class="reward-list-title" [ngStyle]="{ 'background-image': 'url(' + edsContent?.titleImage?.image?.src + ')' }">
                    {{ eventData?.rewardPopupTitle }}</span
                >
                <span>
                    @if (!isDesktop) {
                        <a href="#" (click)="onClosePopup(); webTrackingForCloseAndNotNow('close', 'select rewards popup')">{{
                            clientContent?.messages?.close
                        }}</a>
                    }
                </span>
            </div>

            <div class="reward-list-subtitle" [vnDynamicHtml]="edsContent?.titleImage?.parameters?.description"></div>

            <div class="rewards_list_pop">
                @for (reward of eventData?.playerRewards; track reward; let rewardIndex = $index) {
                    <div class="results-prizes multipul-chooses mb-2">
                        <div
                            class="results-prizes-block"
                            [ngClass]="{ 'block-selected': reward.rewardStatus === 'Claimed', 'block-hover': reward?.isRewardSelected }">
                            <div class="results-prizes-block-content">
                                <div class="results-prizes-title text-truncate">{{ reward?.rewardListDescription }}</div>
                            </div>
                            <div class="results-prizes-block-content">
                                @if (reward?.rewardStatus !== 'Claimed') {
                                    <button class="btn btn-primary btn-md w-100" (click)="chooseReward(reward, rewardIndex)">
                                        {{ clientContent?.messages?.choose }}
                                    </button>
                                }
                            </div>
                            <div class="used-prize">
                                @if (reward?.rewardStatus === 'Claimed') {
                                    <span class="theme-success-i used-prize-icon"></span>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            @if (isDesktop) {
                <div>
                    <div class="rewards_list_pop_btns">
                        <button (click)="onClosePopup(); webTrackingForCloseAndNotNow('click', 'not now')" class="btn btn-secondary btn-lg mr-3">
                            {{ clientContent?.messages?.notnow }}
                        </button>
                        <button (click)="onClickSelect()" [class.disabled]="isButtonDisabled" class="btn btn-primary btn-lg">
                            {{ clientContent?.messages?.select }}
                        </button>
                    </div>
                    <div class="rewards_list_pop_tc">
                        <span class="opt-in-step-content">{{ rewardTnc }}</span>
                    </div>
                </div>
            }

            @if (!isDesktop) {
                <div class="rewards_footer">
                    <div class="">
                        <div>{{ rewardTnc }}</div>
                        <a href="#" (click)="onClosePopup(); webTrackingForCloseAndNotNow('click', 'or choose later link')">
                            <span>{{ clientContent?.messages?.chooselater }}</span></a
                        >
                    </div>
                    <div class="">
                        <button (click)="onClickSelect()" [class.disabled]="isButtonDisabled" class="btn btn-primary btn-lg">
                            {{ clientContent?.messages?.selectreward }}
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
