import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EdsDetails } from '../eds-models/eds-details';

export const edsModuleStateKey = 'edsModuleData';

export const selectEdsFeatureDetails = createFeatureSelector<EdsDetails>(edsModuleStateKey);
export const selectEdsState = (state: EdsDetails) => state;
export const getEdsDetails = createSelector(selectEdsFeatureDetails, selectEdsState);
export const prizeCupImage = createSelector(getEdsDetails, (state: EdsDetails) => state?.edsContent?.edsMyProgress?.prizeCupImage);
