import { NgClass } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { DynamicHtmlDirective, MediaQueryService, NavigationService, UserService } from '@frontend/vanilla/core';
import { LetDirective } from '@ngrx/component';
import { Observable, Subject, combineLatest, map } from 'rxjs';
import Swiper from 'swiper';
import { FreeMode, Navigation, Pagination, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { CommonService } from '../../common-service/common.service';
import { OffersTeaserComponent } from '../../shared-offers/offers-teaser/offers-teaser.component';
import { TrackingModelService } from '../../tracking/tracking-model-service';
import { Offer, OfferCategory, OffersProductStyle } from '../models';
import { MyGalaEnums } from '../my-gala-enums';
import { OfferContentConfiguration } from '../offer-content.client-config';
import { LoadOffersApiService } from '../offers-service/load-offers.service';
import { OffersApiService } from '../offers-service/offers.service';
import { GroupedOffersCountPipe } from '../pipes/grouped-offers.pipe';

interface OffersTeaserGroupViewModel extends FilteredOfferIndexes {
    isDesktop: boolean;
    filtersApplied: boolean;
    clientContentMessages: { [attr: string]: string };
}

interface FilteredOfferIndexes {
    indexes: number[];
    carouselItemCssClass: string;
}

const myGalaBreakpoints = {
    0: {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
    },
    // when window width is >= 480px
    480: {
        slidesPerView: 2,
        slidesPerGroup: 1,
    },
};

@Component({
    selector: 'offers-teaser-group',
    templateUrl: 'offers-teaser-group.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [LetDirective, NgClass, DynamicHtmlDirective, OffersTeaserComponent, GroupedOffersCountPipe],
})
export class OffersTeaserGroupComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() showLhSlider: boolean;
    @Input() showTeaserGroupTitle: boolean;
    @Input() showBackArrow: boolean;
    @Input() showSeeAllLink: boolean;
    @Input() productIndex: number;
    @Input() productsLength: number;
    @Input() isOffersLoadedFromDiffApp: boolean;
    @Input() product: string;
    @Input() offerDetailsList: Offer[];
    @Input() offerCategory: OfferCategory;
    @Input() offersProductStyle: OffersProductStyle;
    @Input() seeAllLinkItemLength: number;
    @Input() isMyGala: boolean;
    filteredIndexes: any = [];
    selectedTab: string;
    swiper: Swiper;
    @ViewChild('swiperContainer') swiperRef: ElementRef;

    vm$: Observable<any>;

    config: SwiperOptions;
    isFreeOffer: boolean;

    viewAllCtaClass: string;
    arrows: boolean;
    myGalaArrows: boolean;
    private readonly destroy$ = new Subject<void>();
    private _isDesktop$: Observable<any>;
    filteredOfferIndexes: number[];

    constructor(
        public carouselDefaultConfig: CarouselDefaultConfig,
        public userService: UserService,
        private offerDataService: OffersApiService,
        private activatedRoute: ActivatedRoute,
        private commonService: CommonService,
        private media: MediaQueryService,
        private navigationService: NavigationService,
        private trackingModelService: TrackingModelService,
        private router: Router,
        private offerContentConfiguration: OfferContentConfiguration,
        public loadOffersApiService: LoadOffersApiService,
        public cdRef: ChangeDetectorRef,
    ) {
        this._isDesktop$ = this.media.observe().pipe(map(() => this.media.isActive('gt-xs')));
    }

    trackByKey(index: number): number {
        return index;
    }

    private setSwiperConfig() {
        this.config = Object.assign(
            {
                modules: [Navigation, Pagination, Virtual, FreeMode],
                preloadImages: true,
                updateOnImagesReady: true,
                spaceBetween: 0,
                on: {
                    imagesReady: () => this.onImagesReady(),
                },
            },
            this.carouselDefaultConfig.defaultConfig,
        );
        if (this.isMyGala) {
            this.myGalaOffersConfig();
        } else {
            this.config.navigation = {
                nextEl: `.${this.product || 'vn-carousel'}.carousel-next`,
                prevEl: `.${this.product || 'vn-carousel'}.carousel-previous`,
            };
            this.config.freeMode = {
                enabled: true,
                momentumRatio: 1,
                momentumVelocityRatio: 1,
                sticky: true,
            };
            this.config.breakpoints = {
                0: {
                    slidesPerView: 1.2,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2.2,
                    slidesPerGroup: 2,
                },
                // when window width is >= 768px
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                // when window width is >= 1280px
                1280: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            };
        }
        // this should be enabled only for qa and test env.
        if (this.offerContentConfiguration.isVirtualSlidesLoadEnabled) {
            this.config.virtual =
                !this.config.loop &&
                !this.config.autoplay &&
                !this.isOffersLoadedFromDiffApp &&
                this.offerContentConfiguration.isVirtualSlidesLoadEnabled;
            this.config.observer = !this.config.virtual;
            this.config.resizeObserver = !this.config.virtual;
            this.config.updateOnWindowResize = !this.config.virtual;
        }
    }

    private myGalaOffersConfig() {
        this.config.navigation = {
            nextEl: `.${this.product || 'vn-carousel'}.swiper-button-next`,
            prevEl: `.${this.product || 'vn-carousel'}.swiper-button-prev`,
        };
        this.config.breakpoints = myGalaBreakpoints;
        this.config.pagination = {
            el: `.${this.product || 'vn-carousel'}.swiper-pagination`,
            clickable: true,
            hideOnClick: false,
            dynamicBullets: true,
            dynamicMainBullets: 5,
        };
        if (this.product.toLowerCase() === MyGalaEnums.FREEOFFERS) {
            this.freeOffersConfig();
        }
    }

    private freeOffersConfig() {
        this.isFreeOffer = true;
        if (!this.offerCategory.freeOffersCarouselConfig?.dots) {
            this.config.pagination = false;
        }
        this.config.breakpoints = {
            0: {
                slidesPerView: 1.2,
                slidesPerGroup: 1,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                slidesPerGroup: this.offerCategory.freeOffersCarouselConfig?.slidesPerGroup
                    ? this.offerCategory.freeOffersCarouselConfig.slidesPerGroup
                    : 2,
            },
        };
        this.config.autoplay = this.offerCategory.freeOffersCarouselConfig?.autoplay
            ? {
                  delay: this.offerCategory.freeOffersCarouselConfig.interval,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: this.offerCategory.freeOffersCarouselConfig.pauseOnHover,
              }
            : true;
        this.config.loop = this.offerCategory.freeOffersCarouselConfig?.loop;
    }

    ngAfterViewInit() {
        this.initSwiper();
    }

    initSwiper() {
        if (!this.swiperRef?.nativeElement) this.cdRef.detectChanges();
        this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
        setTimeout(() => {
            const swiperNavigation = this.swiper?.navigation;
            if (swiperNavigation) {
                swiperNavigation.destroy();
                swiperNavigation.init();
                swiperNavigation.update();
            }
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSwiper(_event: any): void {
        this.swiper.navigation.update();
    }
    onSlidePrev() {
        if (this.swiper) {
            this.swiper.slidePrev();
        }
    }

    onSlideNext() {
        if (this.swiper) {
            this.swiper.slideNext();
        }
    }
    onImagesReady() {
        if (this.productIndex === this.productsLength - 1) {
            this.offerDataService.isOfferGroupComponentLoaded$.next(true);
        }
    }

    processBackClick() {
        this.trackViewAllClickEvent('Back button - Click');

        let url = this.activatedRoute.snapshot.queryParams['rurl'];
        if (url) {
            this.navigationService.storeReturnUrl();
            this.navigationService.goToReturnUrl();
            return;
        }

        url = sessionStorage.getItem('filterPreviousUrl');
        if (url) {
            this.navigationService.goTo(url);
            return;
        }

        url = sessionStorage.getItem('filterdeeplinkingreturnurl');
        if (url) {
            this.navigationService.goTo(url, { queryParamsHandling: 'merge' });
            return;
        }

        url = sessionStorage.getItem('myGalaUrl');
        if (url) {
            this.navigationService.goTo(url);
            return;
        }

        this.commonService.goToOverviewPage(false, 'merge');
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setSwiperConfig();
        if (changes?.showLhSlider && !changes.showLhSlider?.isFirstChange()) {
            this.initSwiper();
        }

        this.viewAllCtaClass = this.getViewAllCtaClass();
        this.arrows = this.carouselDefaultConfig.arrows && this.showLhSlider;

        this.vm$ = combineLatest([
            this._isDesktop$,
            this.loadOffersApiService.offerDetails$.asObservable(),
            this.loadOffersApiService.clientContentMessages$.asObservable(),
            this.loadOffersApiService.areFiltersApplied$,
        ]).pipe(
            map(([isDesktop, offerDetails, clientContentMessages, areFiltersApplied]) => {
                this.filteredOfferIndexes = this.loadOffersApiService.filteredOfferIndexes(
                    offerDetails?.offerCategories,
                    offerDetails?.offerDetailsList,
                    this.product,
                );
                if (this.isMyGala && this.filteredOfferIndexes?.length > 0 && this.config?.breakpoints != null) {
                    const breakpoints = this.config?.breakpoints[480]?.slidesPerView;
                    this.myGalaArrows =
                        this.product.toLowerCase() === MyGalaEnums.FREEOFFERS
                            ? this.carouselDefaultConfig.arrows && Number(breakpoints) < this.filteredOfferIndexes.length
                            : this.carouselDefaultConfig.arrows;
                }
                return {
                    indexes: this.filteredOfferIndexes?.length > 0 ? this.filteredOfferIndexes : [],
                    carouselItemCssClass: this.getCarouselItemCssClass(this.filteredOfferIndexes?.length),
                    isDesktop: isDesktop,
                    filtersApplied: areFiltersApplied,
                    clientContentMessages: clientContentMessages,
                } as OffersTeaserGroupViewModel;
            }),
        );
    }
    private getCarouselItemCssClass(filteredOffersLength: number): string {
        if (filteredOffersLength === 1) {
            return 'col-12';
        } else if (filteredOffersLength === 2) {
            return 'col-6';
        } else if (filteredOffersLength === 3) {
            return 'col-4';
        } else if (filteredOffersLength >= 4) {
            return 'col-3';
        }
        return '';
    }

    private getViewAllCtaClass(): string {
        if (this.isMyGala) {
            const cssClass = this.offerContentConfiguration.viewAllCssClass ? this.offerContentConfiguration.viewAllCssClass : 'btn-primary';
            return 'btn btn-md ' + cssClass;
        }
        return '';
    }

    trackPrevNextEvent(eventDetails: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promotions',
            LabelEvent: 'my gala',
            ActionEvent: 'click',
            LocationEvent: this.product,
            EventDetails: 'carousel ' + eventDetails,
        });
    }

    processViewAllClick() {
        this.navigationService.goTo(this.offerCategory.viewAllLink?.url, {
            queryParamsHandling: 'merge',
            appendReferrer: !this.navigationService.location?.url()?.includes('/promo/'),
        });
        if (this.isMyGala) this.trackMyGalaViewAllCTAClicksFromTiles();
        else this.trackViewAllClickEvent('View All Link - Click', this.offerCategory?.viewAllLink?.url);

        sessionStorage.removeItem('myGalaUrl');
        if (this.isMyGala && this.router.url.includes('promo')) sessionStorage.setItem('myGalaUrl', this.router.url);
    }

    trackViewAllClickEvent(actionEvent: string, offersPageUrl: string = '') {
        this.trackingModelService.submitTracking({
            LabelEvent: this.product,
            ActionEvent: actionEvent,
            URLClicked: offersPageUrl ? offersPageUrl : this.product,
        });
    }

    private trackMyGalaViewAllCTAClicksFromTiles() {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promotions',
            LabelEvent: 'my gala',
            ActionEvent: 'click',
            LocationEvent: this.product,
            EventDetails: 'view all cta',
            contentPosition: '1',
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
