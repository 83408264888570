import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { WebAnalyticModel } from '../../../../common/web-tracking/web-analytic.model';

@Injectable({
    providedIn: 'root',
})
export class CommonEventService {
    isOptinLoaded: Subject<boolean> = new Subject<boolean>();
    private isAnimationOver = new BehaviorSubject(false);
    private isTabChanged = new BehaviorSubject(false);
    private webAnalyticModel = new BehaviorSubject({} as WebAnalyticModel);

    sendWAModel(wa: WebAnalyticModel) {
        this.webAnalyticModel.next(wa);
    }

    getWAModel(): Observable<WebAnalyticModel> {
        return this.webAnalyticModel.asObservable();
    }

    sendTabChangedFlag(tabChanged: boolean) {
        this.isTabChanged.next(tabChanged);
    }

    getTabChangedFlag(): Observable<boolean> {
        return this.isTabChanged.asObservable();
    }

    sendIfAnimationIsOver(status: boolean) {
        this.isAnimationOver.next(status);
    }

    getIfAnimationIsOver(): Observable<boolean> {
        return this.isAnimationOver.asObservable();
    }
}
