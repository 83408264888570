import { createFeatureSelector, createSelector } from '@ngrx/store';
import { uniqBy } from 'lodash-es';

import { LeaderboardRankingStoreModel } from '../../models/ranking/ranking-models';

export const leaderboardRankingModuleStateKey = 'leaderboardRankingModuleData';

export const leaderboardFeatureDetails = createFeatureSelector<LeaderboardRankingStoreModel>(leaderboardRankingModuleStateKey);
export const leaderboardRankingState = (state: LeaderboardRankingStoreModel) => state;
export const leaderboardRankingDetails = createSelector(leaderboardFeatureDetails, leaderboardRankingState);
export const leaderboardMetaData = createSelector(leaderboardRankingDetails, (state) => state?.leaderboardMetaData);
export const playerCount = createSelector(leaderboardMetaData, (state) => state?.playerCount);
export const leaderboardDetails = createSelector(leaderboardMetaData, (state) => {
    const sortedLeaderBoardDetails = state?.leaderBoardDetails?.sort((x, y) => x.rank - y.rank);
    return uniqBy(sortedLeaderBoardDetails, 'playerScreenName');
});
export const currentPlayerRankDetails = createSelector(leaderboardMetaData, (state) => state?.currentPlayerRankDetails);
export const rankingImageContent = createSelector(leaderboardFeatureDetails, (state) => state?.leaderboardContent?.rankingImageContent);
