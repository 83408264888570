import { Component, Input, OnInit } from '@angular/core';

import { MediaQueryService } from '@frontend/vanilla/core';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { Offer, OfferCategory } from '../models';
import { LoadOffersApiService } from '../offers-service/load-offers.service';

export interface OffersViewModel {
    offerProducts: string[];
    showLhSlider: boolean;
    isLobbyEmpty: boolean;
    showSkeleton: boolean;
    seeAllLinkItemLength: number;
    offerDetailsList: Offer[];
    offerCategories: { [key: string]: OfferCategory };
}

@Component({
    template: '',
    standalone: true,
})
export class BaseOverviewPageComponent implements OnInit {
    @Input() products: string[];
    @Input() isOffersLoadedFromDiffApp: boolean;

    _loadOffersAfterLogin$: Observable<any>;
    _offersDetails$: Observable<any>;
    _seeAllLinkItemLength$: Observable<any>;
    _filteredProducts$: Observable<any>;
    _showLhSlider$: Observable<any>;
    baseVm$: Observable<any>;

    constructor(
        public media: MediaQueryService,
        public loadOffersApiService: LoadOffersApiService,
    ) {
        this.loadBaseVm();
    }

    ngOnInit(): void {
        this.loadOffersApiService.loadMultiBrandDetails();
        this.loadOffersApiService.loadClientContent();
        this.loadOffersApiService.loadOffers(this.products);
    }

    loadBaseVm() {
        this._loadOffersAfterLogin$ = this.loadOffersApiService.loadOffersAfterLogin$.pipe(
            tap((loadOffersAfterLogin) => {
                if (loadOffersAfterLogin) {
                    this.loadOffersApiService.loadOffers(this.products, false);

                    if (!this.isOffersLoadedFromDiffApp) {
                        this.loadOffersApiService.loadCarousel(true);
                    }
                }
            }),
        );

        this._offersDetails$ = this.loadOffersApiService.offerDetails$.pipe(
            tap(() => {
                if (!this.isOffersLoadedFromDiffApp) {
                    window.scroll(0, 0);
                }
            }),
        );

        this._seeAllLinkItemLength$ = this.media.observe().pipe(
            map(() => {
                let seeAllLinkItemLength = 3;
                if (this.media.isActive('xs')) {
                    seeAllLinkItemLength = 2;
                } else if (this.media.isActive('gt-md')) {
                    seeAllLinkItemLength = 4;
                }
                return seeAllLinkItemLength;
            }),
        );

        this._filteredProducts$ = this.loadOffersApiService.filteredProducts$;

        this._showLhSlider$ = this._filteredProducts$.pipe(map((products) => products?.length > 1 || this.isOffersLoadedFromDiffApp));

        this.baseVm$ = combineLatest([
            this._showLhSlider$,
            this._seeAllLinkItemLength$,
            this._offersDetails$,
            this._filteredProducts$,
            this.loadOffersApiService.isLobbyEmpty$.asObservable(),
            this.loadOffersApiService.showSkeleton$.asObservable(),
            this._loadOffersAfterLogin$,
        ]).pipe(
            distinctUntilChanged(),
            map(([showLhSlider, seeAllLinkItemLength, offersDetails, filteredProducts, isLobbyEmpty, showSkeleton]) => {
                return {
                    offerProducts: filteredProducts,
                    showLhSlider: showLhSlider,
                    isLobbyEmpty: isLobbyEmpty,
                    showSkeleton: showSkeleton,
                    seeAllLinkItemLength: seeAllLinkItemLength,
                    offerDetailsList: offersDetails.offerDetailsList,
                    offerCategories: offersDetails.offerCategories,
                } as OffersViewModel;
            }),
        );
    }
}
