import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { ContentService, DslService, NavigationService, PCImage, UserService, ViewTemplate } from '@frontend/vanilla/core';
import { AdobeTargetOptions, AdobeTargetService } from '@frontend/vanilla/features/adobe-target';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { OffersConstants } from '../common-constants/offers-constants';
import { Carousel, FilterOffersTabs, OfferMetadata, SelectedFilters } from '../models';

@Injectable({
    providedIn: 'root',
})
export class OffersApiService {
    isOfferGroupComponentLoaded$ = new BehaviorSubject(false);

    filteredPokerTicket$ = new BehaviorSubject([] as number[]);
    itemSelected$ = new BehaviorSubject<string>('');
    tabChanged$ = new BehaviorSubject<boolean>(false);
    resetAll$ = new BehaviorSubject<boolean>(false);
    carouselDetails$ = new BehaviorSubject<Carousel>({} as Carousel);

    //To track the page name selected by user
    public pageSelected: any;

    public globalSelectedFilters = new BehaviorSubject<SelectedFilters[]>([]);
    public globalFilterTabs = new BehaviorSubject<FilterOffersTabs[]>([]);
    isFilterPopupClicked$ = new BehaviorSubject<boolean>(false);
    isResetSubNavOnFilterApplied$ = new BehaviorSubject<boolean>(false);
    private filteredOffersSubject = new BehaviorSubject<any>(null);
    filteredOffers$ = this.filteredOffersSubject.asObservable();
    topNavigationContent$ = new BehaviorSubject<any>({});
    private readonly destroy$ = new Subject<void>();
    constructor(
        private apiService: ApiService,
        private navService: NavigationService,
        private userService: UserService,
        private contentService: ContentService,
        private vnTargetService: AdobeTargetService,
        private dslService: DslService,
    ) {}

    get FilteredPokerTicket() {
        return this.filteredPokerTicket$.asObservable();
    }
    get loadedOfferGroupComponent() {
        return this.isOfferGroupComponentLoaded$.asObservable();
    }
    updateFilteredOffers(data: any) {
        this.filteredOffersSubject.next(data);
    }
    loadMobileOfferHistory(): Observable<PCImage> {
        return this.contentService.getJsonFiltered(OffersConstants.MobileOfferHistoryPath);
    }

    updateOffer(offerMetadata: OfferMetadata) {
        if (
            this.userService.isAuthenticated &&
            !offerMetadata?.isSpecialOffer &&
            !offerMetadata.isFreeOffer &&
            !offerMetadata.isWelcomeOffer &&
            !offerMetadata.isGroupedTile
        ) {
            this.apiService.post('updateoffer', offerMetadata, { showSpinner: false }).subscribe();
        }
    }

    loadCarousel() {
        this.apiService
            .get('getCarouselItems')
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: Carousel) => {
                if (res) {
                    this.loadTargetOffer(res);
                }
            });
    }

    loadContent() {
        return this.apiService
            .get('getClientContent')
            .pipe(switchMap((clientContent) => this.dslService.evaluateContent<ViewTemplate>(clientContent)));
    }

    loadMultipleBrandsDetails() {
        return this.contentService.getJsonFiltered(OffersConstants.MultipleBrandDetailsPath);
    }

    loadTargetOffer(carousel: Carousel) {
        const options: AdobeTargetOptions = {
            mbox: 'promo-ph-carousel',
        };

        this.vnTargetService
            .getOffer(options)
            .pipe(
                switchMap((offerPathOrId) => {
                    if (!offerPathOrId) return EMPTY;
                    else return this.apiService.get('getPromoContent', { contentId: offerPathOrId });
                }),
            )
            .subscribe((res) => {
                if (res && carousel && carousel.carouselItems) carousel.carouselItems.push(res);
            });
        this.carouselDetails$.next(carousel);
    }

    goToOffersMoreInfo(url: string) {
        this.navService.goTo(url, {
            appendReferrer: this.navService.location?.url()?.includes('/promo/') ? false : true,
            forceReload: this.hasForceReloadEnabled(url),
        });
    }

    hasForceReloadEnabled(url: string) {
        return url.includes('promoreload=true') || undefined;
    }

    loadTopNavigationContent() {
        this.contentService.getJsonFiltered('Promo/PromoHub/ClientContent/TopNavigationContent').subscribe((res) => {
            this.topNavigationContent$.next(res);
        });
    }
}
