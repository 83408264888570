import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { PromotionsGetSingleResponse } from './promotions.models';

@Injectable()
export class PromotionsResourceService {
    constructor(private api: ApiService) {}
    getSingle(product: string | null, folderId: string | null): Observable<PromotionsGetSingleResponse> {
        return this.api.get('getSingle', { product: product, folderId: folderId });
    }
}
