import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ClientConfigService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Subject, filter, takeUntil } from 'rxjs';

import { Offer } from '../../offers/models';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';
import { OffersSkeletonComponent } from '../../shared-offers/offers-skeleton/offers-skeleton.component';
import { BetStationConfiguration } from '../bet-station-client-config';
import { BetStationOfferTileTeaserComponent } from '../bet-station-offers-teaser/bet-station-offer-tile-teaser.component';

@Component({
    selector: 'ph-bet-station-offer-tiles',
    templateUrl: 'bet-station-offer-tiles.component.html',
    standalone: true,
    imports: [NgScrollbarModule, OffersSkeletonComponent, BetStationOfferTileTeaserComponent],
})
export class BetStationOfferTilesComponent implements OnInit, OnDestroy {
    @Input() shouldLoadPromoClientConfig: boolean = true;
    offersAreLoading: boolean;
    offerDetailsList: Offer[];
    promoClientConfigLoaded: boolean;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private betStationConfiguration: BetStationConfiguration,
        private userService: UserService,
        private clientConfigService: ClientConfigService,
        public loadOffersApiService: LoadOffersApiService,
    ) {}

    async ngOnInit() {
        await this.loadPromoClientConfig();
        this.offersAreLoading = true;
        this.loadOffersApiService.loadBetStationSpecialOffers();
        this.loadOffersApiService.loadClientContent();

        this.loadOffersAfterLogin();

        this.loadOffersApiService.loadBetStationSpecialOffers$.pipe(takeUntil(this.destroy$)).subscribe((offerDetailsList: Offer[]) => {
            this.offerDetailsList = offerDetailsList;
            this.offersAreLoading = false;
        });
    }

    async loadPromoClientConfig(): Promise<void> {
        if (this.shouldLoadPromoClientConfig && !this.betStationConfiguration?.isConfigReady) {
            await this.clientConfigService.load('', [BetStationConfiguration]);
        }
        this.promoClientConfigLoaded = true;
    }

    private loadOffersAfterLogin() {
        this.userService.events
            .pipe(
                filter((e) => e instanceof UserLoginEvent),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                if (this.userService.isAuthenticated) {
                    this.loadOffersApiService.loadBetStationSpecialOffers();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
