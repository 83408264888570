export class WebAnalyticModel {
    page: string;
    tab: string;
    promoId: string;
    campaignId: string;
    messageType: string;
    position: string;
    contentPosition: string;
    product: string;
    promoOfferName: string;
    promotionType: string;
    labelEvent: string;
}
