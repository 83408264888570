import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { DynamicHtmlDirective, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject, EMPTY, Observable, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { NLRegulatoryHelperService } from '../../spanish-regulatory/NL-regulatory/NL-regulatory-helper.service';
import { RegulatoryApiService } from '../../spanish-regulatory/regulatory-api.service';
import { RegulatoryConfiguration } from '../../spanish-regulatory/regulatory-client-config';
import { SpanishRegulatoryHelperService } from '../../spanish-regulatory/spanish-regulatory-helper.service';
import { OffersConstants } from '../common-constants/offers-constants';

@Component({
    selector: 'user-compliance-message',
    templateUrl: './user-compliance-message.component.html',
    // Had to set change detection strategy to default, otherwise the anchor links are not loading in vnDynamicHtml
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [LetDirective, DynamicHtmlDirective],
})
export class UserComplianceMessageComponent implements OnInit, OnDestroy {
    _userServiceEvents$: Observable<any>;
    messages$: Observable<any>;
    showNonComplianceError$: BehaviorSubject<boolean>;

    constructor(
        private regulatoryConfiguration: RegulatoryConfiguration,
        private spanishRegulatoryHelperService: SpanishRegulatoryHelperService,
        private regulatoryApiService: RegulatoryApiService,
        private userService: UserService,
        private nLRegulatoryHelperService: NLRegulatoryHelperService,
    ) {}

    ngOnInit(): void {
        this._userServiceEvents$ = this.userService.events?.pipe(
            filter((e) => e instanceof UserLoginEvent && this.userService.isAuthenticated),
            startWith(EMPTY),
        );

        this.messages$ = combineLatest([
            this.spanishRegulatoryHelperService.reasonCodes$,
            this.nLRegulatoryHelperService.complianceKey$,
            this.spanishRegulatoryHelperService.nonUserComplianceMessages$,
            this.nLRegulatoryHelperService.nonUserComplianceMessages$,
            this._userServiceEvents$,
            this._userServiceEvents$,
        ]).pipe(
            map(([reasonCodes, NLcomplianceKey, spainDecreeClientContent, NLClientContent]) => {
                let nonComplianceMessageKey: string = '';
                if (this.regulatoryConfiguration?.enableSpanishRegulatory) {
                    if (reasonCodes?.length > 0) {
                        nonComplianceMessageKey = reasonCodes[0] ?? '';
                    } else if (!this.userService.isAuthenticated) {
                        nonComplianceMessageKey = OffersConstants?.NonLoggedInForSpainLabel;
                    }
                    const spainDecreeClientContentMessages = spainDecreeClientContent?.messages;
                    if (spainDecreeClientContentMessages !== undefined && nonComplianceMessageKey !== undefined) {
                        return {
                            nonComplianceMessage: spainDecreeClientContentMessages[nonComplianceMessageKey],
                            nonComplianceSecondMessage:
                                spainDecreeClientContentMessages[nonComplianceMessageKey + OffersConstants?.ExtendedReasonCode],
                        };
                    }
                } else if (this.regulatoryConfiguration?.enableNLRestrictions) {
                    const nlClientContentMessages = NLClientContent?.messages;
                    if (nlClientContentMessages !== undefined) {
                        return {
                            nonComplianceMessage: nlClientContentMessages[NLcomplianceKey],
                        };
                    }
                }
                return {};
            }),
        );

        this.showNonComplianceError$ = this.spanishRegulatoryHelperService.showNonComplianceErrorMessage$;

        if (this.regulatoryConfiguration?.enableSpanishRegulatory) {
            this.regulatoryApiService.fetchSpanishDecreeContent();
        }
        if (this.regulatoryConfiguration?.enableNLRestrictions) {
            this.regulatoryApiService.fetchNLCashbackContent();
        }
    }

    ngOnDestroy() {
        this.spanishRegulatoryHelperService.showNonComplianceErrorMessage$.next(false);
    }
}
