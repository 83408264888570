<div class="offers-teaser-v2" (click)="navigateToOfferMoreInfo(); $event?.stopPropagation()">
    <div class="offers-teaser-v2-img-cont">
        <img [src]="offer?.offerContent?.teaserImage ? offer?.offerContent?.teaserImage?.src : ''" />
        <div class="active status-pills">
            {{ clientContentMessages?.active }}
        </div>
        @if (betStationConfiguration?.enableOfferTypeBadge) {
            <offer-type-badge class="promotions-tab" [offer]="offer"></offer-type-badge>
        }

        <div class="offers-teaser-v2-badges" [ngClass]="offer?.offerMetadata?.showExpiryDate ? 'offersdata' : 'offersnodata'">
            @if (offer?.offerMetadata?.showExpiryDate) {
                <div class="offer-badge txt-s offer-expire">
                    <div class="badge-countdown">
                        <span class="badge-countdown__icon theme-clock"></span>
                        <div class="badge-countdown__text">
                            {{ offer?.offerContent?.expiryTimeCountDown }}
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="offers-teaser-v2-content">
        <h3 class="offers-teaser-v2-title">{{ offer?.offerContent?.title }}</h3>
        @if (offer?.offerContent?.keyTermsAndConditions) {
            <div class="offers-teaser-v2-conditions">
                <span class="mb-0 txt-xtr-small" [vnDynamicHtml]="offer?.offerContent?.keyTermsAndConditions"></span>
            </div>
        }
    </div>
    @if (offer?.offerMetadata?.isSpecialOffer) {
        <div class="offers-teaser-v2-actions betstation-offers">
            <div class="betstation-offers-teaser-btn">
                @if (!!offer?.offerContent?.primaryCta) {
                    @if (!!moreInfoCta?.url) {
                        <a
                            [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-light btn-lg'"
                            class="w-100"
                            (click)="navigateToOfferMoreInfo(); $event?.stopPropagation()">
                            {{ moreInfoCta?.text }}
                        </a>
                    }
                    @if (isSpecialOfferOptin) {
                        <a
                            [ngClass]="
                                !!offer?.offerContent?.primaryCta?.attributes?.class
                                    ? offer?.offerContent?.primaryCta?.attributes?.class
                                    : 'btn btn-primary btn-lg'
                            "
                            class="w-100 btn-lg"
                            data-offer-id
                            [attr.data-offer-id]="Special_offer_Id"
                            [attr.data-offer-type]="Special_offer_Type"
                            [attr.data-offer-message-error]="optinInvalidMessage"
                            [attr.data-offer-message-expired]="optinExpiredMessage"
                            [attr.data-offer-message-invalid]="optinInvalidMessage"
                            [attr.data-offer-message-not-offered]="optinUserNotEligibleMessage"
                            [attr.data-offer-message-offered]="optInMessage"
                            [attr.data-offer-message-opted-in]="optedInMessage"
                            [attr.data-offer-message-opted-out]=""
                            (click)="specialOffer_Click($event); $event?.stopPropagation()">
                            {{ offer?.offerContent?.primaryCta?.text }}
                        </a>
                    }
                    @if (!!offer?.offerContent?.primaryCta?.url && !isSpecialOfferOptin) {
                        <a
                            [ngClass]="
                                !!offer?.offerContent?.primaryCta?.attributes?.class
                                    ? offer?.offerContent?.primaryCta?.attributes?.class
                                    : 'btn btn-light btn-lg'
                            "
                            class="w-100"
                            href="#"
                            (click)="trackBetStationClick('opt-in', teaserNumber, offer?.offerContent?.primaryCta); $event?.stopPropagation()">
                            {{ offer?.offerContent?.primaryCta?.text }}
                        </a>
                    }
                }
                @if (!!!offer?.offerContent?.primaryCta) {
                    @if (!!moreInfoCta?.url) {
                        <a
                            [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-light btn-lg'"
                            class="w-100 btn-lg"
                            (click)="navigateToOfferMoreInfo(); $event?.stopPropagation()">
                            {{ moreInfoCta?.text }}
                        </a>
                    }
                }
            </div>
        </div>
    }
</div>
