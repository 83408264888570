import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CardRushStateModel } from '../card-rush-models/card-rush-state-model';

export const cardRushModuleStateKey: string = 'cardRushModuleData';

export const cardRushFeatureDetails = createFeatureSelector<CardRushStateModel>(cardRushModuleStateKey);
export const cardRushStateModel = (state: CardRushStateModel) => state;

export const cardRushState = createSelector(cardRushFeatureDetails, cardRushStateModel);
export const cardRushLeaderboardRewards = createSelector(cardRushFeatureDetails, (state) => state.leaderboardRewardsContent);
export const cardRushLeaderboardResults = createSelector(cardRushFeatureDetails, (state) => state.leaderboardResultsContent);
export const cardRushLeaderboardResultsInfos = createSelector(cardRushLeaderboardResults, (state) => state?.resultsPlayerInfos);
