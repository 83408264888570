import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ClientConfigService } from '@frontend/vanilla/core';

import { RegulatoryConfiguration } from '../../spanish-regulatory/regulatory-client-config';
import { OffersProductStyle } from '../models';
import { OfferContentConfiguration } from '../offer-content.client-config';
import { OffersByProductComponent } from '../offers-by-product/offers-by-product.component';

@Component({
    selector: 'ph-offers-overview',
    templateUrl: './offers-overview.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [OffersByProductComponent],
})
export class OffersOverviewComponent implements OnInit {
    @Input() products: string[];
    @Input() shouldLoadPromoClientConfig: boolean = true;
    @Input() showTeaserSkeleton: boolean;
    @Input() offersProductStyle: OffersProductStyle;
    promoClientConfigLoaded: boolean;

    constructor(
        private clientConfigService: ClientConfigService,
        private offerContentConfiguration: OfferContentConfiguration,
        private regulatoryConfig: RegulatoryConfiguration,
    ) {}

    async ngOnInit() {
        await this.loadPromoClientConfig();
    }

    async loadPromoClientConfig(): Promise<void> {
        if (this.shouldLoadPromoClientConfig) {
            const configs = [] as any;
            if (!this.offerContentConfiguration.isConfigReady) configs.push(OfferContentConfiguration);
            if (!this.regulatoryConfig.isConfigReady) configs.push(RegulatoryConfiguration);
            if (configs.length > 0) await this.clientConfigService.load('', configs);
        }
        this.promoClientConfigLoaded = true;
    }
}
