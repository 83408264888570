import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FreespinsResourceService {
    constructor(private apiService: ApiService) {}

    getFreeSpinsHistoryInitValues(): Observable<any> {
        return this.apiService.get('phClientContent', {
            paths: ['Promo/PromoHub/History/FreeSpinsHistory'],
        });
    }
}
