import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { GENERIC_MODAL_CONTENT } from '@frontend/promo-homewidget';
import { DeviceService, ViewTemplate } from '@frontend/vanilla/core';

import { EdsContent } from '../../eds-models/eds-content';
import { EdsEventData } from '../../eds-models/eds-event-data';
import { EdsMultiRewardPopupComponent } from '../eds-multi-reward-popup/eds-multi-reward-popup.component';

@Injectable({
    providedIn: 'root',
})
export class OverlayDialogModal {
    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private deviceService: DeviceService,
    ) {}
    openDialogModal(edsId: string, eventData: EdsEventData, clientContent: ViewTemplate, edsContent: EdsContent) {
        const data = {
            edsId: edsId,
            eventData: eventData,
            clientContent: clientContent,
            edsContent: edsContent,
        };
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            scrollStrategy: this.overlay?.scrollStrategies?.block(),
            positionStrategy: this.overlay?.position()?.global()?.centerHorizontally()?.centerVertically(),
            panelClass: this.deviceService.isMobile ? ['generic-modal-drawer'] : ['generic-modal-popup'],
        });
        const component = new ComponentPortal(
            EdsMultiRewardPopupComponent,
            null,
            Injector.create({
                providers: [
                    { provide: GENERIC_MODAL_CONTENT, useValue: data },
                    { provide: OverlayRef, useValue: overlayRef },
                ],
                parent: this.injector,
            }),
        );
        overlayRef.attach(component);
    }
}
