@if (toShow) {
    <div>
        <div class="progress-bar" [ngClass]="{ 'progress-bar-with-stripes': offerContentConfiguration?.isBetMgmTileRedesignEnabled }">
            @if (!isTileContentV2Enabled) {
                <div class="progress-bar-value">
                    <span class="percentage-span">{{ progressBarValue }}</span>
                    <span class="text-muted-secondary bonus-card-heading-1-small"></span>
                </div>
                <div class="background-bar overlay-bg" style="width: 100%">
                    <div
                        class="progress"
                        [ngClass]="{ 'bg-gray-300': offer.offerMetadata.offerState == 'PAUSED' }"
                        [style.width.%]="progressBarWidthPercentage"></div>
                </div>
            }
            @if (isTileContentV2Enabled) {
                <div class="progress-bar-value">
                    <span>{{ progressBarValue }}</span>
                </div>
                <div class="background-bar" [ngClass]="{ 'overlay-bg': !offerContentConfiguration?.isBetMgmTileRedesignEnabled }" style="width: 100%">
                    <div class="progress" [style.width.%]="progressBarWidthPercentage"></div>
                </div>
            }
        </div>
    </div>
}
