<ng-container *ngrxLet="vm$ as vm">
    @if (vm) {
        @if (!isTileContentV2Enabled) {
            <div
                (click)="offerTileClick()"
                class="offers-teaser pc-component pc-teaser pc-banner-tile banner-tile-hover dh"
                (mouseover)="isCardOnHover = true"
                (mouseleave)="isCardOnHover = false">
                <offer-teaser-image
                    [isParentOnHover]="isCardOnHover"
                    [offer]="offer"
                    [moreInfo]="moreInfoCta"
                    [product]="product"
                    [teaserNumber]="teaserNumber"
                    [showProgressBar]="showProgressBar"
                    [isTileContentV2Enabled]="isTileContentV2Enabled">
                </offer-teaser-image>
                <div class="pc-header">
                    <h3 class="pc-h">
                        <a
                            class="pc-h-a"
                            (click)="updateOffers()"
                            [attr.href]="moreInfoCta?.url"
                            target="{{ !!moreInfoCta?.attributes?.target ? moreInfoCta?.attributes?.target : '' }}"
                            >{{ offer.offerContent?.title }}</a
                        >
                    </h3>
                </div>
                <div class="pc-t-h-cont">
                    <div class="offer-badge txt-s text-white offer-type-container">
                        @if (offer.offerMetadata?.isNewOffer) {
                            <span class="badge-product badge-new">
                                {{ vm.newTag }}
                            </span>
                        }
                        @if (offer.offerMetadata?.isGroupedTile && groupedOffersCount) {
                            <div class="offer-badge txt-s text-white offer-type-container">
                                <span class="badge-product mr-1">
                                    {{ groupedOffersCount }}
                                </span>
                            </div>
                        }
                        <offer-type-badge [offer]="offer"></offer-type-badge>
                        <offer-applicable-sports-league [offerContent]="offer?.offerContent"> </offer-applicable-sports-league>
                    </div>
                    @if (offer?.offerContent?.eligibleLeagueName) {
                        <div class="offer-badge txt-s eligibleLeague-wrapper">
                            <span class="eligibleLeague">
                                {{ offer?.offerContent?.eligibleLeagueName }}
                            </span>
                        </div>
                    }
                    <div class="offers-teaser-v2-badges">
                        @if (offer.offerMetadata?.showExpiryDate) {
                            <div class="offer-badge txt-s offer-expire">
                                @if (!isTimeCountdownEnabled) {
                                    <div class="badge-countdown">
                                        <div class="badge-countdown__text">
                                            {{ vm.expiresTag }}:
                                            {{ offer.offerMetadata?.formattedEndDate }}
                                        </div>
                                    </div>
                                }
                                @if (isTimeCountdownEnabled) {
                                    <div class="badge-countdown">
                                        @if (!isEpcotEnabled) {
                                            <span class="badge-countdown__icon theme-clock"></span>
                                        }
                                        <div class="badge-countdown__text">
                                            {{ offer?.offerContent?.expiryTimeCountDown }}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div class="offer-content-conditions">
                        <p class="txt-xtr-small mb-0" [vnDynamicHtml]="offer.offerContent?.keyTermsAndConditions"></p>
                    </div>
                </div>
            </div>
        }
        @if (isTileContentV2Enabled) {
            <div class="offers-teaser-v2" (mouseover)="isCardOnHover = true" (mouseleave)="isCardOnHover = false" (click)="offerTileClick()">
                <offer-teaser-image
                    [isParentOnHover]="isCardOnHover"
                    [offer]="offer"
                    [moreInfo]="moreInfoCta"
                    [product]="product"
                    [showProgressBar]="showProgressBar"
                    [isGroupedPage]="isGroupedPage"
                    [offerType]="offerType"
                    [teaserNumber]="teaserNumber"
                    [isTileContentV2Enabled]="isTileContentV2Enabled">
                </offer-teaser-image>
                <div class="offers-teaser-v2-content-wrapper d-flex flex-column h-100">
                    <div class="offers-teaser-v2-content">
                        @if (!isBetMgmTileRedesignEnabled) {
                            <h3 class="offers-teaser-v2-title">{{ offer.offerContent?.title }}</h3>
                        }
                        <div class="offers-teaser-v2-badges">
                            <div class="offers-teaser-v2-badges-row" [class.offers-teaser-flex-wrapping]="offer.offerMetadata?.isNewOffer">
                                <!-- If else check -->
                                @if (offer.offerMetadata?.offerBadges) {
                                    <div class="offer-badge">
                                        @for (badge of offer.offerMetadata?.offerBadges; track badge) {
                                            <span class="badge-countdown__icon" [ngClass]="badge"></span>
                                        }
                                    </div>
                                }
                                @if (offer.offerMetadata?.isNewOffer) {
                                    <div class="offer-badge txt-s text-white offer-type-container">
                                        <span class="badge-product badge-new">
                                            {{ vm.newTag }}
                                        </span>
                                    </div>
                                }
                                @if (offer.offerMetadata?.isGroupedTile && groupedOffersCount) {
                                    <div class="offer-badge txt-s text-white offer-type-container">
                                        <span class="badge-product mr-1">
                                            {{ groupedOffersCount }}
                                        </span>
                                    </div>
                                }
                                @if (offer.offerMetadata.offersCount && !offer.offerMetadata?.isGroupedTile) {
                                    <div class="offer-badge txt-s text-white offer-type-container">
                                        <span class="badge-product mr-1">
                                            {{ offer.offerMetadata.offersCount + 'X' }}
                                        </span>
                                    </div>
                                }
                                <offer-type-badge [offer]="offer"></offer-type-badge>
                                @if ((offer.offerContent.applicableSports || offer.offerContent.sportsGameType) && !isBetMgmTileRedesignEnabled) {
                                    <div class="offer-sports-overflow">
                                        <offer-applicable-sports-league [offerContent]="offer.offerContent"> </offer-applicable-sports-league>
                                    </div>
                                }
                            </div>
                            @if (!isBetMgmTileRedesignEnabled && offer?.offerContent?.eligibleLeagueName) {
                                <div class="offer-badge txt-s eligibleLeague-wrapper">
                                    <span class="eligibleLeague">
                                        {{ offer.offerContent.eligibleLeagueName }}
                                    </span>
                                </div>
                            }
                            @if (offer.offerMetadata?.showExpiryDate) {
                                <div class="offer-badge txt-s offer-expire">
                                    @if (!isTimeCountdownEnabled) {
                                        <div class="badge-countdown">
                                            <div class="badge-countdown__text">
                                                {{ vm.expiresTag }}{{ vm.expiresColon }}
                                                {{ offer.offerMetadata?.formattedEndDate }}
                                            </div>
                                        </div>
                                    }
                                    @if (isTimeCountdownEnabled) {
                                        <div
                                            [ngClass]="
                                                offer?.offerContent?.expiryDateClass ? offer?.offerContent?.expiryDateClass : 'badge-countdown'
                                            ">
                                            <span
                                                class="badge-countdown__icon"
                                                [ngClass]="isBetMgmTileRedesignEnabled ? 'theme-time' : 'theme-clock'"></span>
                                            <div class="badge-countdown__text">
                                                {{ offer?.offerContent?.expiryTimeCountDown }}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        @if (isBetMgmTileRedesignEnabled) {
                            <div>
                                @if (showProgressBar) {
                                    <progress-bar [offer]="offer" [isTileContentV2Enabled]="isTileContentV2Enabled"> </progress-bar>
                                }
                                <h3 class="offers-teaser-v2-title">{{ offer.offerContent?.title }}</h3>
                            </div>
                        }
                        @if (offer.offerContent?.keyTermsAndConditions) {
                            <div class="offers-teaser-v2-conditions">
                                <span class="mb-0 txt-xtr-small" [vnDynamicHtml]="offer.offerContent.keyTermsAndConditions"></span>
                            </div>
                        }
                    </div>
                    @if (!offer.offerMetadata?.isSpecialOffer && !offer.offerMetadata?.isWelcomeOffer) {
                        <offer-teaser-cta
                            class="offers-teaser-v2-actions"
                            [offer]="offer"
                            [teaserNumber]="teaserNumber"
                            [product]="product"
                            [moreInfoCta]="moreInfoCta"
                            [isGroupedPage]="isGroupedPage"
                            [offerType]="offerType"></offer-teaser-cta>
                    }
                    @if (offer.offerMetadata?.isSpecialOffer || offer.offerMetadata?.isWelcomeOffer) {
                        <offer-teaser-special-offer-cta
                            class="offers-teaser-v2-actions"
                            [offer]="offer"
                            [teaserNumber]="teaserNumber"
                            [product]="product"
                            [isMyGala]="isMyGala"
                            [moreInfoCta]="moreInfoCta"></offer-teaser-special-offer-cta>
                    }
                    <ng-content select="[isCashierDepositOffers]"></ng-content>
                </div>
            </div>
        }
    }
</ng-container>
