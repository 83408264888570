import { LeaderboardContent } from './leaderboard-content';
import { LeaderBoardMetaData } from './leaderboard-metadata';

export class LeaderBoardDetails {
    leaderBoardMetadata: LeaderBoardMetaData;
    leaderBoardContent: LeaderboardContent;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export class LeaderBoardRequest {
    promoId: string;
    minRank: number;
    maxRank: number;
    requestType: string;
    isAutoOptin: boolean;
}
