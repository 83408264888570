import { Pipe, PipeTransform } from '@angular/core';

import { OfferCategory, OfferMetadata } from '../models';

@Pipe({
    name: 'groupedOffersCount',
    pure: true,
    standalone: true,
})
export class GroupedOffersCountPipe implements PipeTransform {
    transform(offerMetadata: OfferMetadata, offerCategory: OfferCategory): number {
        if (offerMetadata.optInApi !== undefined && offerCategory?.noOfGroupedOffersPerOfferType !== undefined) {
            return offerCategory.noOfGroupedOffersPerOfferType[offerMetadata.optInApi] ?? 0;
        }
        return 0;
    }
}
