import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AwardSchedule } from '../models/award-schedule/award-schedule';
import { PlayerActivityDetailsStoreModel } from '../models/player-activity-store-model';

export const playerActivityModuleStateKey = 'playerActivityModuleData';

export const playerActivityFeatureDetails = createFeatureSelector<PlayerActivityDetailsStoreModel>(playerActivityModuleStateKey);
export const playerActivityState = (state: PlayerActivityDetailsStoreModel) => state;
export const playerActivityDetails = createSelector(playerActivityFeatureDetails, playerActivityState);
const playerActivityContent = createSelector(playerActivityFeatureDetails, (state) => state.playerActivityContent);
const playerPromoConfiguration = createSelector(playerActivityFeatureDetails, (state) => state.playerActivityMetaData?.playerPromoConfiguration);
export const preRequisite = createSelector(playerActivityContent, (state) => state?.preRequisite);
export const totalWagered = createSelector(playerActivityContent, (state) => state?.totalWagered);
export const isMyProgressDisabled = createSelector(playerPromoConfiguration, (state) => {
    return state && state.minCriteria && state?.minCriteria?.minCriteriaQualified == false;
});
export const showCheckButton = createSelector(
    playerPromoConfiguration,
    (playerPromoConfiguration) => playerPromoConfiguration?.awardSchedule?.toLocaleLowerCase() !== AwardSchedule.ENDOFPROMOTION,
);

export const singleSlabPromo = createSelector(
    playerPromoConfiguration,
    (playerPromoConfiguration) => playerPromoConfiguration?.criteria?.slabDetails?.length > 1,
);
