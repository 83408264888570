<ng-container *ngrxLet="vm$ as vm">
    @if (vm) {
        <div class="d-flex align-items-center offers-teaser-tiles">
            @if (!!offer?.offerContent?.primaryCta) {
                @if (!!moreInfoCta?.url) {
                    <a
                        [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-link btn-link-light'"
                        class="btn-md"
                        [attr.href]="moreInfoCta?.url"
                        (click)="navigateToOfferMoreInfo($event); $event?.stopPropagation()"
                        target="{{ !!moreInfoCta?.attributes?.target ? moreInfoCta?.attributes?.target : '' }}">
                        {{ moreInfoCta?.text }}
                    </a>
                }
                @if (isSpecialOfferOptIn && !offerContentConfiguration?.isBetMgmTileRedesignEnabled) {
                    <a
                        [ngClass]="
                            !!offer?.offerContent?.primaryCta?.attributes?.class
                                ? offer?.offerContent?.primaryCta?.attributes?.class
                                : 'btn btn-primary'
                        "
                        class="w-100 btn-md justify-content-center"
                        data-offer-id
                        [attr.data-offer-id]="Special_offer_Id"
                        [attr.data-offer-type]="Special_offer_Type"
                        [attr.data-offer-message-error]="vm?.optInInvalidMessage"
                        [attr.data-offer-message-expired]="vm?.optInExpiredMessage"
                        [attr.data-offer-message-invalid]="vm?.optInInvalidMessage"
                        [attr.data-offer-message-not-offered]="vm?.optInUserNotEligibleMessage"
                        [attr.data-offer-message-offered]="vm?.optInMessage"
                        [attr.data-offer-message-opted-in]="vm?.optedInMessage"
                        [attr.data-offer-message-opted-out]=""
                        (click)="specialOffer_Click($event); $event?.stopPropagation()"
                        id="NonBetmgmTileRedesign">
                        {{ offer?.offerContent?.primaryCta?.text }}
                    </a>
                }
                @if (isSpecialOfferOptIn && offerContentConfiguration?.isBetMgmTileRedesignEnabled) {
                    <a
                        [ngClass]="
                            !!offer?.offerContent?.primaryCta?.attributes?.class
                                ? offer?.offerContent?.primaryCta?.attributes?.class
                                : 'btn btn-primary'
                        "
                        class="w-100 btn-md justify-content-center"
                        data-offer-id
                        [attr.data-offer-id]="Special_offer_Id"
                        [attr.data-offer-type]="Special_offer_Type"
                        [attr.data-offer-message-error]="vm?.optedInErrorMessage"
                        [attr.data-offer-message-expired]="vm?.optInExpiredMessage"
                        [attr.data-offer-message-invalid]="vm?.optInInvalidMessage"
                        [attr.data-offer-message-not-offered]="vm?.optInUserNotEligibleMessage"
                        [attr.data-offer-message-offered]="vm?.optInMessage"
                        [attr.data-offer-message-opted-in]="vm?.optedInMessage"
                        [attr.data-offer-message-opted-out]=""
                        (click)="specialOffer_Click($event); $event?.stopPropagation()"
                        id="BetmgmTileRedesign">
                        {{ offer?.offerContent?.primaryCta?.text }}
                    </a>
                }
                @if (!!offer?.offerContent.primaryCta?.url && !isSpecialOfferOptIn) {
                    <a
                        [ngClass]="
                            !!offer?.offerContent?.primaryCta?.attributes?.class
                                ? offer?.offerContent?.primaryCta?.attributes?.class
                                : 'btn btn-link btn-link-light'
                        "
                        class="btn-md"
                        (click)="trackClickEvent(teaserNumber, offer?.offerContent?.primaryCta, $event)"
                        [attr.href]="offer?.offerContent?.primaryCta?.url"
                        target="{{
                            !!offer?.offerContent?.primaryCta?.attributes?.target ? offer?.offerContent?.primaryCta?.attributes?.target : ''
                        }}">
                        {{ offer?.offerContent?.primaryCta?.text }}
                    </a>
                }
            }
            @if (!!!offer?.offerContent?.primaryCta) {
                @if (!!moreInfoCta?.url) {
                    <a
                        [attr.href]="moreInfoCta?.url"
                        [ngClass]="
                            !!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-secondary single-info-btn more-info-btn'
                        "
                        class="btn-md"
                        (click)="navigateToOfferMoreInfo($event)"
                        target="{{ !!moreInfoCta?.attributes?.target ? moreInfoCta?.attributes?.target : '' }}">
                        {{ moreInfoCta?.text }}
                    </a>
                }
            }
        </div>
    }
</ng-container>
