import { createAction, props } from '@ngrx/store';

import { LeaderboardRankingRequest } from '../../models/ranking/leaderboard-ranking-request';
import { LeaderboardRankingStoreModel, PlayerRankDetails } from '../../models/ranking/ranking-models';

export const leaderboardRankingInit = createAction('[Leaderboard Ranking] Init');
export const leaderboardRankingDestroyed = createAction('[Leaderboard Ranking] Destroyed');
export const loadLeaderBoardRankingData_Success = createAction('[Leaderboard Ranking] Load_Success', props<LeaderboardRankingStoreModel>());
export const getLeaderboardRankingScrollUpData = createAction(
    '[Leaderboard Ranking] Get Scroll Up Details',
    props<{ leaderBoardRequest: LeaderboardRankingRequest; showSpinner: boolean }>(),
);
export const getLeaderboardRankingScrollDownData = createAction(
    '[Leaderboard Ranking] Get Scroll Down Details',
    props<{ leaderBoardRequest: LeaderboardRankingRequest; showSpinner: boolean }>(),
);
export const loadLeaderboardRankingScrollUpData = createAction(
    '[Leaderboard Ranking] Load Scroll Up Details',
    props<{ leaderboardDetails: PlayerRankDetails[] }>(),
);
export const loadLeaderboardRankingScrollDownData = createAction(
    '[Leaderboard Ranking] Load Scroll Down Details',
    props<{ leaderboardDetails: PlayerRankDetails[] }>(),
);
