import { animate, style, transition, trigger } from '@angular/animations';
import { KeyValuePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ContentLink, MediaQueryService, UserService } from '@frontend/vanilla/core';
import { LetDirective } from '@ngrx/component';
import { pickBy } from 'lodash-es';
import { Subject, map } from 'rxjs';

import { OffersConstants } from '../../../offers/common-constants/offers-constants';
import { Offer } from '../../../offers/models';
import { OfferContentConfiguration } from '../../../offers/offer-content.client-config';
import { ProgressBarComponent } from '../../progress-bar/progress-bar.component';
import { OfferTeaserStatusComponent } from '../offer-teaser-status/offer-teaser-status.component';
import { OfferTeaserService } from '../offer-teaser.service';

@Component({
    selector: 'offer-teaser-image',
    templateUrl: 'offer-teaser-image.component.html',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({ opacity: 0 }), animate(150, style({ opacity: 1 }))]),
            transition(':leave', [animate(150, style({ opacity: 0 }))]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [OfferTeaserStatusComponent, ProgressBarComponent, NgClass, LetDirective, KeyValuePipe],
})
export class OfferTeaserImageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() offer: Offer;
    @Input() product: string;
    @Input() moreInfo: ContentLink;
    @Input() teaserNumber: any;
    @Input() isParentOnHover: boolean;
    @Input() isTileContentV2Enabled: boolean;
    @Input() isGroupedPage: boolean = false;
    @Input() offerType: string = '';
    @Input() showProgressBar: boolean;

    moreInfoImageCta: ContentLink;
    ctaLinks: { [key: string]: ContentLink };
    bindUpdateApiCall: boolean;
    offerStateClass: string;
    isUserAuthenticated: boolean;
    private readonly destory$ = new Subject<void>();
    _isDesktop$: any;
    UpdatedOptinMessage: string = '';
    constructor(
        private media: MediaQueryService,
        private userService: UserService,
        private offerTeaserService: OfferTeaserService,
        public offerContentConfiguration: OfferContentConfiguration,
    ) {
        this._isDesktop$ = this.media.observe().pipe(map(() => this.media.isActive('gt-xs')));
    }

    ngOnInit() {
        this.isUserAuthenticated = this.userService.isAuthenticated;
    }

    ngOnChanges() {
        this.bindCtaLinks();
        this.bindUpdateApiCallOnCtaLink();
        this.offerStateClass = this.getOfferStateClass();
    }

    private bindCtaLinks(): void {
        if (this.offer.offerContent?.ctaLinks != undefined && Object.keys(this.offer.offerContent.ctaLinks)?.length > 0) {
            this.ctaLinks = pickBy(this.offer.offerContent.ctaLinks, (_, key) => key !== OffersConstants.Claim);
            if (Object.keys(this.ctaLinks)?.length > 0 && !!this.ctaLinks[OffersConstants.MoreInfo]) {
                const url = this.offer.offerContent.ctaLinks[OffersConstants.MoreInfo] ?? ({} as ContentLink);
                this.ctaLinks[OffersConstants.MoreInfo] = this.offerTeaserService.bindMoreInfoUrl(
                    url,
                    this.product,
                    this.offer.offerMetadata.isGroupedTile,
                );
            }
            return;
        }
        this.ctaLinks = {};
    }

    private bindUpdateApiCallOnCtaLink(): void {
        this.bindUpdateApiCall = this.offer.offerMetadata.isNewOffer;
    }

    private getOfferStateClass(): string {
        if (!!this.offer.offerMetadata.offerState && this.offer.offerMetadata.offerState.toLowerCase().match('play|active|pause')) {
            return 'active';
        } else {
            return !this.offer.offerMetadata.offerState || 0 === this.offer.offerMetadata.offerState.trim().length
                ? 'active'
                : this.offer.offerMetadata.offerState.toLowerCase();
        }
    }

    navigateToOfferMoreInfo(cta: ContentLink) {
        this.offerTeaserService.navigateToOfferMoreInfo(this.teaserNumber, cta, this.offer, true, this.product, this.isGroupedPage, this.offerType);
    }
    ngOnDestroy(): void {
        this.destory$.next();
        this.destory$.complete();
    }
}
