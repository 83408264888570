import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import { Offer } from '../../offers/models';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';

@Component({
    selector: 'offer-type-badge',
    templateUrl: 'offer-type-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LetDirective, NgClass],
})
export class OfferTypeBadgeComponent implements OnChanges {
    @Input() offer: Offer;
    vm$: Observable<any>;
    showOfferType: boolean;
    offerBadgeClass: string = 'badge-product ';

    constructor(private loadOffersApiService: LoadOffersApiService) {
        this.vm$ = this.loadOffersApiService.offerTeaserClientContent$;
    }

    ngOnChanges() {
        this.isOfferWithTypeLabel();
    }

    isOfferWithTypeLabel(): void {
        if (
            this.offer?.offerContent?.offerType != null &&
            Object.keys(this.offer?.offerContent?.offerType)?.length > 0 &&
            this.offer?.offerContent?.offerType?.text != ''
        ) {
            this.showOfferType = true;
            this.offerBadgeClass += this.offer.offerContent.offerType.cssClass;
        }
    }
}
