import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { NavigationService, PlainLinkComponent } from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';

import { Offer, OfferCategory } from '../../offers/models';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';
import { OffersTeaserComponent } from '../../shared-offers/offers-teaser/offers-teaser.component';

const breakpoints = {
    0: {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
    },
    // when window width is >= 480px
    480: {
        slidesPerView: 2.2,
        slidesPerGroup: 2,
    },
    // when window width is >= 768px
    768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },
    // when window width is >= 1280px
    1280: {
        slidesPerView: 4,
        slidesPerGroup: 4,
    },
};

@Component({
    selector: 'offers-by-user-priority',
    templateUrl: './offers-by-user-priority.component.html',
    styleUrls: ['./offers-by-user-priority.component.css'],
    standalone: true,
    imports: [PlainLinkComponent, NgClass, OffersTeaserComponent],
})
export class OffersByUserPriorityComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() offerCategory: OfferCategory;
    @Input() categoryType: string;
    @Input() offerDetailsList: Offer[];

    clientContentMessages: { [attr: string]: string };
    private offersDestroyed$ = new Subject<void>();
    config: SwiperOptions;
    arrows: boolean;
    swiper: Swiper;

    @ViewChild('swiperContainer') swiperRef: ElementRef;

    constructor(
        public carouselDefaultConfig: CarouselDefaultConfig,
        private navigationService: NavigationService,
        public loadOffersApiService: LoadOffersApiService,
    ) {}

    ngOnInit() {
        this.loadClientContent();
    }

    ngOnChanges() {
        this.setSwiperConfig();
    }

    private setSwiperConfig() {
        this.config = Object.assign(
            {
                spaceBetween: 0,
            },
            this.carouselDefaultConfig?.defaultConfig,
        );
        this.config.navigation = {
            nextEl: `.${this.categoryType || 'vn-carousel'}.carousel-next`,
            prevEl: `.${this.categoryType || 'vn-carousel'}.carousel-previous`,
        };
        this.config.breakpoints = breakpoints;
        this.arrows = this.carouselDefaultConfig?.arrows;
    }

    ngAfterViewInit() {
        this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
    }

    loadClientContent() {
        this.loadOffersApiService.loadClientContent();
        this.loadOffersApiService.clientContentMessages$
            .pipe(takeUntil(this.offersDestroyed$))
            .subscribe((clientContentMessages: { [attr: string]: string }) => {
                if (clientContentMessages) {
                    this.clientContentMessages = clientContentMessages;
                }
            });
    }

    processViewAllClick() {
        this.navigationService.goTo(this.offerCategory?.viewAllLink?.url);
    }

    ngOnDestroy(): void {
        this.offersDestroyed$?.next();
        this.offersDestroyed$?.complete();
    }
}
