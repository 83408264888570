<ng-container *ngrxLet="vm$ as vm">
    @if (vm) {
        @if (offer?.offerContent?.ctaLinks) {
            <div class="d-flex align-items-center offers-teaser-tiles">
                <!-- Use 'justify-content-center' class when have one button ( no 'w-100' class for btn)on the middle -->
                <a
                    [attr.href]="moreInfoCta?.url"
                    (click)="navigateToOfferMoreInfo(); $event.stopPropagation(); $event.preventDefault()"
                    [ngClass]="moreInfoCtaClass"
                    class="btn btn-md"
                    id="moreInfoCta"
                    >{{ moreInfoCta?.text }}</a
                >
                @if (
                    (vm.showOptedInErrorMessage || vm.showOptedInMessage || (!vm.isOptInValid && isOptInPresent)) &&
                    !offerContentConfiguration.isBetMgmTileRedesignEnabled
                ) {
                    <a
                        class="offer-button w-100 offer-button-tiles offer-button-md"
                        [ngClass]="{
                            'danger theme-error-i': vm.showOptedInErrorMessage,
                            'success theme-success-i': vm.showOptedInMessage,
                            'info theme-info': !vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent
                        }">
                        @if (vm.showOptedInErrorMessage) {
                            <span>{{ vm.optedInErrorMessage }}</span>
                        }
                        @if (vm.showOptedInMessage) {
                            <span>{{ vm.optedInMessage }}</span>
                        }
                        @if (!vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent) {
                            <span>{{ vm.optInInvalidMessage }}</span>
                        }
                    </a>
                } @else {
                    @if (
                        (vm.showOptedInErrorMessage || vm.showOptedInMessage || (!vm.isOptInValid && isOptInPresent)) &&
                        offerContentConfiguration.isBetMgmTileRedesignEnabled
                    ) {
                        <a
                            class="btn btn-md w-100 offers-teaser-tiles-btn"
                            [ngClass]="{
                                'btn-secondary disabled': !vm.showOptedInMessage
                            }">
                            <span
                                [ngClass]="{
                                    'danger theme-error-i': vm.showOptedInErrorMessage,
                                    'success theme-success-i': vm.showOptedInMessage,
                                    'warning theme-warning': !vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent
                                }"></span>
                            @if (vm.showOptedInErrorMessage) {
                                <span>{{ vm.optedInErrorMessage }}</span>
                            }
                            @if (vm.showOptedInMessage) {
                                <span>{{ vm.optedInMessage }}</span>
                            }
                            @if (!vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent) {
                                <span>{{ vm.optInInvalidMessage }}</span>
                            }
                        </a>
                    } @else {
                        @if (vm.showOptInButton && !vm.optInClicked && !vm.showButtonSpinner && !vm.showOptedInErrorMessage && optInCta?.text) {
                            <button
                                (click)="optIn_Click($event); $event.stopPropagation()"
                                [ngClass]="!!optInCta.attributes?.class ? optInCta.attributes?.class : 'btn btn-primary'"
                                class="btn-md w-100">
                                {{ optInCta.text }}
                            </button>
                        }
                    }
                }
                @if (offer?.offerContent?.betNowCta && offerContentConfiguration?.enableBetNowCta) {
                    <a
                        [href]="offer?.offerContent?.betNowCta?.url"
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        class="btn btn-md btn-primary w-100"
                        id="betNowCta"
                        >{{ offer?.offerContent?.betNowCta?.text }}</a
                    >
                }
                @if (vm.showButtonSpinner) {
                    <a class="btn btn-primary btn-md btn-loading w-100"></a>
                }
            </div>
        }
    }
</ng-container>
