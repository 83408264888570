import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DynamicHtmlDirective, PlainLinkComponent } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

import { Offer } from '../../../offers/models';
import { OfferContentConfiguration } from '../../../offers/offer-content.client-config';
import { OfferTeaserService } from '../offer-teaser.service';

@Component({
    selector: 'offer-teaser-status',
    templateUrl: 'offer-teaser-status.component.html',
    standalone: true,
    imports: [CommonModule, PlainLinkComponent, DynamicHtmlDirective],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class OfferTeaserStatusComponent implements OnInit, OnDestroy {
    @Input() offer: Offer;
    @Input() isUserAuthenticated: any;
    @Input() offerStateClass: any;
    private readonly destory$ = new Subject<void>();
    constructor(
        public offerContentConfiguration: OfferContentConfiguration,
        private offerTeaserService: OfferTeaserService,
    ) {}
    ngOnInit() {
        this.offerTeaserService.currentOptedInMessageSource$.pipe(takeUntil(this.destory$)).subscribe((data) => {
            if (data[this.offer?.offerMetadata?.crmServiceId] != null) {
                this.offer.offerMetadata.isOfferActive = true;
                this.offer.offerMetadata.showOptedInStatus = true;
                this.offer.offerContent.offerStateString = data[this.offer.offerMetadata?.crmServiceId] || '';
            }
        });
    }
    ngOnDestroy(): void {
        this.destory$.next();
        this.destory$.complete();
    }
}
