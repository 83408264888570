<ng-container *ngrxLet="{ messages: messages$, showNonComplianceError: showNonComplianceError$ } as vm">
    @if (vm?.messages; as messages) {
        @if (messages?.nonComplianceMessage) {
            <div class="offers-home-page message-panel">
                <div class="theme-info-i">
                    <div class="cms-container" [vnDynamicHtml]="messages?.nonComplianceMessage"></div>
                </div>
                @if (vm?.showNonComplianceError) {
                    <div class="theme-error-i">
                        <div class="cms-container" [vnDynamicHtml]="messages?.nonComplianceSecondMessage"></div>
                    </div>
                }
            </div>
        }
    }
</ng-container>
