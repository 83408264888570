import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prOffersHistoryDetails', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: PromoOffersHistoryConfigFactory,
})
export class PromoOffersHistoryConfiguration extends LazyClientConfigBase {
    amountOfDisplayedSingleMonths: number;
    cutOffMonth: number;
    cutOffYear: number;
    dateCollectionLastYear: number;
    pageSize: number;
    enableLocalDateTimeFormat: boolean;
}

export function PromoOffersHistoryConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoOffersHistoryConfiguration);
}
