import { Pipe, PipeTransform } from '@angular/core';

import { FilteredOfferByCategory, Offer, OfferCategory } from '@frontend/promo-homewidget';

@Pipe({
    name: 'filterByOfferType',
    standalone: true,
})
export class FilterByOfferTypePipe implements PipeTransform {
    transform(
        offerDetailsList: Array<Offer>,
        offerCategories: { [key: string]: OfferCategory },
        offerTypes: string[],
    ): Array<FilteredOfferByCategory> {
        const filteredOffersByCategory = new Array<FilteredOfferByCategory>();

        const offerDetailsListLength = offerDetailsList?.length;
        if (offerDetailsListLength > 0 && offerCategories) {
            for (const [product, offerCategory] of Object.entries(offerCategories)) {
                for (const index of offerCategory.offerDetailsIndexes) {
                    if (offerDetailsListLength > index && offerTypes.includes(offerDetailsList[index].offerMetadata?.serviceType?.toLowerCase())) {
                        filteredOffersByCategory.push({
                            offer: offerDetailsList[index],
                            product: product,
                        });
                    }
                }
            }
        }

        return filteredOffersByCategory;
    }
}
