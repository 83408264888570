import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { OfferContent } from '../../offers/models';
import { OfferContentConfiguration } from '../../offers/offer-content.client-config';

@Component({
    selector: 'offer-applicable-sports-league',
    templateUrl: 'offer-applicable-sports-league.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class OfferApplicableSportsLeagueComponent implements OnChanges {
    @Input() offerContent: OfferContent;
    sportsNameAndGameTypes: string;
    constructor(public offerContentConfiguration: OfferContentConfiguration) {}

    ngOnChanges() {
        if (this.offerContent) {
            if (this.offerContent.applicableSports && this.offerContent.sportsGameType) {
                this.sportsNameAndGameTypes = this.offerContent.applicableSports + ' |' + this.offerContent.sportsGameType;
            } else if (this.offerContent.applicableSports) {
                this.sportsNameAndGameTypes = this.offerContent.applicableSports;
            } else if (this.offerContent.sportsGameType) {
                this.sportsNameAndGameTypes = this.offerContent.sportsGameType;
            }
        }
    }
}
