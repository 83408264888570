import { ContentImage } from '@frontend/vanilla/core';

import { ViewType } from '../view-type';

export class LeaderboardRankingStoreModel {
    leaderboardContent: LeaderboardContentStoreModel;
    leaderboardMetaData: LeaderboardMetaDataStoreModel;
}

export interface LeaderboardContentStoreModel {
    rankingImageContent: RankingImageContent;
}

export interface LeaderboardMetaDataStoreModel {
    currentPlayerRankDetails: CurrentPlayerRankDetails;
    leaderBoardDetails: PlayerRankDetails[];
    playerCount?: number;
}

export class PlayerRankDetails {
    rank: number;
    playerScreenName: string;
    points: string;
    award: string;
    isCurrentUser: boolean = false;
}

export class CurrentPlayerRankDetails {
    rankDetails: PlayerRankDetails;
    nextRewardDetails: NextRewardDetails;
}

export class NextRewardDetails {
    rank: number;
    description: string;
    award: string;
}
export class RankingImageContent {
    highlightedRowBackground: ContentImage;
    highlightedRowRibbon: ContentImage;
}

export class StickyRow {
    stickyTop: boolean = false;
    stickyBottom: boolean = false;
    scrollInRow: boolean = false;
    isStickOnce: boolean = false;
    stickyElementPosition: number;
    stickyElementHeight: number;
}

export class RankingModel {
    isScrolled: boolean = false;
    isScrollDown: boolean = false;
    globalMinRank: number;
    globalMaxRank: number;
    viewType: string = ViewType.view;
    topOffSet: number;
    downEmptyCallCount: number = 0;
    isThreeColumn: boolean = false;
}
