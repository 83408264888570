import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prMyGalaOffers', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promoConfigFactory,
})
export class MyGalaOffersConfiguration extends LazyClientConfigBase {
    isMyGalaEnabled: boolean;
    offerCardsLayout: OfferCardsLayout[][];
}

export function promoConfigFactory(service: LazyClientConfigService) {
    return service.get(MyGalaOffersConfiguration);
}

export class OfferCardsLayout {
    name: string;
    type: string;
}
