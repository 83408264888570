import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prTargetPromotionDetails', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: TargetPromotionConfigurationFactory,
})
export class TargetPromotionDetailConfiguration extends LazyClientConfigBase {
    fetchRankingCount: string;
    leaderBoardMaxRank: string;
    isLBRankingV2Enabled: boolean;
}

export function TargetPromotionConfigurationFactory(service: LazyClientConfigService) {
    return service.get(TargetPromotionDetailConfiguration);
}
