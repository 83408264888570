import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { ContentLink } from '@frontend/vanilla/core';
import { OfferButtonComponent } from '@frontend/vanilla/features/offer-button';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject } from 'rxjs';

import { Offer, OfferTeaserClientContent } from '../../../offers/models';
import { OfferContentConfiguration } from '../../../offers/offer-content.client-config';
import { LoadOffersApiService } from '../../../offers/offers-service/load-offers.service';
import { OfferTeaserService } from '../offer-teaser.service';

@Component({
    selector: 'offer-teaser-special-offer-cta',
    templateUrl: 'offer-teaser-special-offer-cta.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [LetDirective, NgClass, OfferButtonComponent],
})
export class OfferTeaserSpecialOfferCtaComponent implements OnChanges {
    @Input() offer: Offer;
    @Input() teaserNumber: number;
    @Input() product: string;
    @Input() isMyGala: boolean;
    @Input() moreInfoCta: ContentLink;
    @Input() isGroupedPage: boolean = false;
    @Input() offerType: string = '';

    vm$: BehaviorSubject<OfferTeaserClientContent>;

    isSpecialOfferOptIn: boolean;
    isMoreInfoClicked = false;
    isClickEventTracked = false;

    constructor(
        private offerTeaserService: OfferTeaserService,
        public loadOffersApiService: LoadOffersApiService,
        public offerContentConfiguration: OfferContentConfiguration,
    ) {
        this.vm$ = this.loadOffersApiService.offerTeaserClientContent$;
    }

    get promoId() {
        return this.offer?.offerMetadata?.crmServiceId;
    }
    get Special_offer_Id() {
        return this.offer?.offerContent?.primaryCta?.attributes != null ? this.offer?.offerContent?.primaryCta?.attributes['data-offer-id'] : '';
    }
    get Special_offer_Type() {
        return this.offer?.offerContent?.primaryCta?.attributes != null ? this.offer?.offerContent?.primaryCta?.attributes['data-offer-type'] : '';
    }

    ngOnChanges() {
        this.isSpecialOfferOptIn = this.offer?.offerMetadata?.isSpecialOfferOptin;
    }

    trackClickEvent(position: any, cta: ContentLink, event: Event) {
        if (!this.isClickEventTracked) {
            this.offerTeaserService.trackClickEvent(
                position,
                cta,
                this.product,
                this.offer?.offerMetadata?.isGroupedTile,
                this.offer?.offerMetadata?.serviceSubType,
                this.promoId,
                this.isMyGala,
                this.isGroupedPage,
                this.offerType,
                this.offer,
            );
            this.isClickEventTracked = true;
            event?.stopPropagation();
        }
    }

    navigateToOfferMoreInfo(event: Event) {
        if (!this.isMoreInfoClicked) {
            this.offerTeaserService.navigateToOfferMoreInfo(this.teaserNumber, this.moreInfoCta, this.offer, false, this.product);
            this.isMoreInfoClicked = true;
        }
        if (event) {
            event?.stopPropagation();
        }
    }

    specialOffer_Click(evt: any) {
        if (evt?.currentTarget?.attributes['data-offer-status']?.value !== 'offered') {
            this.navigateToOfferMoreInfo(evt);
        }
    }
}
