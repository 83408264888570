import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ContentLink, ViewTemplate } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

import { CashierEventTypes, EventData } from '../cashier-deposit/models/cashier-deposit-model';
import { DepositsOffersEvents } from '../cashier-deposit/service/deposit-offers-events.service';
import { OffersConstants } from '../common-constants/offers-constants';
import { Offer } from '../models';
import { LoadOffersApiService } from '../offers-service/load-offers.service';

@Component({
    selector: 'deposits-offers-teaser',
    templateUrl: 'deposits-offers-teaser.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class CashierDepositOffersTeaserComponent implements OnInit, OnDestroy {
    @Input() offer: Offer;
    showSuccessMessage: boolean;
    moreInfoCta: ContentLink;
    clientContent: ViewTemplate;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private loadOffersApiService: LoadOffersApiService,
        private depositsOffersEvents: DepositsOffersEvents,
    ) {}

    ngOnInit() {
        if (
            !this.offer.offerMetadata?.isSpecialOffer &&
            this.offer.offerContent?.ctaLinks != undefined &&
            Object.keys(this.offer.offerContent?.ctaLinks)?.length > 0
        ) {
            this.moreInfoCta = this.offer.offerContent.ctaLinks[OffersConstants.MoreInfo] ?? ({} as ContentLink);
        }
    }

    loadClientContent() {
        this.loadOffersApiService.clientContentMessages$.pipe(takeUntil(this.destroy$)).subscribe((res: ViewTemplate) => {
            if (res) {
                this.clientContent = res;
            }
        });
    }

    depositOfferDeselect(event: any, offerMetadata: any) {
        this.showSuccessMessage = true;
        offerMetadata.isBonusApplied = false;
        event.stopPropagation();
        const eventData = new EventData();
        eventData.event = CashierEventTypes.bonusRemoved;
        this.depositsOffersEvents.sendBonusCodeDetails(eventData);
    }

    depositOfferSelect(event: any, offerMetadata: any) {
        offerMetadata.isBonusApplied = true;
        this.showSuccessMessage = false;
        event.stopPropagation();
        const eventData = new EventData();
        eventData.event = CashierEventTypes.bonusSelected;
        eventData.bonusCode = 'BONUSCODE1'; // To do once CRM changes are done
        eventData.bonusCode = offerMetadata?.bonusCode;
        this.depositsOffersEvents.sendBonusCodeDetails(eventData);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
