@if (isTileContentV2Enabled) {
    <div class="offers-teaser-v2-img-cont">
        <img [src]="offer.offerContent?.teaserImage ? offer.offerContent.teaserImage.src : ''" />
        <offer-teaser-status [offer]="offer" [isUserAuthenticated]="isUserAuthenticated" [offerStateClass]="offerStateClass"></offer-teaser-status>
        @if (!offerContentConfiguration?.isBetMgmTileRedesignEnabled) {
            <div>
                @if (showProgressBar) {
                    <progress-bar
                        [offer]="offer"
                        [ngClass]="{ 'ph-content-opacify': isParentOnHover }"
                        [isTileContentV2Enabled]="isTileContentV2Enabled">
                    </progress-bar>
                }
            </div>
        }
    </div>
}

@if (!isTileContentV2Enabled) {
    @if (offer.offerContent) {
        <div class="pc-t-img-cont">
            @if (offer.offerContent.offerTypeIcon) {
                <img
                    class="icon bonus-card-icon"
                    [attr.src]="offer.offerContent.offerTypeIcon.src"
                    [attr.alt]="offer.offerContent.offerTypeIcon.alt"
                    [width]="offer.offerContent.offerTypeIcon.width"
                    [height]="offer.offerContent.offerTypeIcon.height" />
            }
            @if (offer.offerContent?.offerStateString && offer.offerMetadata.isOfferActive && isUserAuthenticated) {
                <div [ngClass]="['status-pills', offerStateClass]">
                    {{ offer.offerContent?.offerStateString }}
                </div>
            }
            <a class="pc-img-a" [attr.href]="moreInfo?.url" target="{{ !!moreInfo?.attributes?.target ? moreInfo?.attributes?.target : '' }}">
                @if (offer.offerContent.teaserImage) {
                    <img
                        class="pc-img"
                        [attr.src]="offer.offerContent.teaserImage.src"
                        [attr.alt]="offer.offerContent.teaserImage.alt"
                        [width]="offer.offerContent.teaserImage.width"
                        [height]="offer.offerContent.teaserImage.height"
                        (click)="navigateToOfferMoreInfo(moreInfo); $event.stopPropagation(); $event.preventDefault()" />
                }
            </a>
            @if (showProgressBar && offer.offerMetadata.offerState == 'PAUSED') {
                <div class="pause-icon-container">
                    <div class="pause-btn">
                        <div class="pause-btn-strokes"></div>
                        <div class="pause-btn-strokes right"></div>
                    </div>
                </div>
            }
            @if (showProgressBar) {
                <progress-bar [offer]="offer" [ngClass]="{ 'ph-content-opacify': isParentOnHover }" [isTileContentV2Enabled]="isTileContentV2Enabled">
                </progress-bar>
            }
            <ng-container *ngrxLet="_isDesktop$ as isDesktop">
                @if (isParentOnHover && isDesktop) {
                    <div [@fadeInOut]>
                        @if (offer.offerMetadata?.isSpecialOffer || offer.offerMetadata?.isWelcomeOffer) {
                            <div class="banner-tile-hover-content">
                                @if (!!offer.offerContent.primaryCta?.url) {
                                    <a
                                        [ngClass]="
                                            !!offer.offerContent.primaryCta?.attributes?.class
                                                ? offer.offerContent.primaryCta?.attributes?.class
                                                : 'btn btn-primary'
                                        "
                                        target="{{
                                            !!offer.offerContent.primaryCta?.attributes?.target
                                                ? offer.offerContent.primaryCta?.attributes?.target
                                                : ''
                                        }}"
                                        [attr.href]="offer.offerContent.primaryCta?.url"
                                        >{{ offer.offerContent.primaryCta?.text }}</a
                                    >
                                }
                                @if (!!moreInfo?.url) {
                                    <a
                                        [ngClass]="!!moreInfo?.attributes?.class ? moreInfo?.attributes?.class : 'btn btn-dark'"
                                        target="{{ !!moreInfo?.attributes?.target ? moreInfo?.attributes?.target : '' }}"
                                        [attr.href]="moreInfo?.url"
                                        >{{ moreInfo?.text }}</a
                                    >
                                }
                            </div>
                        }
                        @if (!offer.offerMetadata?.isSpecialOffer && !offer.offerMetadata?.isWelcomeOffer) {
                            <div class="banner-tile-hover-content">
                                @for (cta of ctaLinks | keyvalue; track cta) {
                                    <a
                                        [attr.href]="cta?.value?.url"
                                        (click)="navigateToOfferMoreInfo(cta?.value); $event.stopPropagation(); $event.preventDefault()"
                                        [ngClass]="!!cta?.value?.attributes?.class ? cta?.value?.attributes?.class : 'btn btn-dark'"
                                        target="{{ !!cta?.value?.attributes?.target ? cta?.value?.attributes?.target : '' }}"
                                        ><span>{{ cta?.value?.text }}</span></a
                                    >
                                }
                            </div>
                        }
                    </div>
                }
            </ng-container>
        </div>
    }
}
