@if (!offerContentConfiguration?.enableSkeletonV2) {
    <div class="bg-home-skeleton sk-promohub-container">
        @if (showTeaserSkeleton) {
            <div class="sk-banner"></div>
        }
        @if (showTitleSkeleton) {
            <div class="sk-title-wrapper">
                <div class="sk-title"></div>
            </div>
        }
        <div class="sk-tiles-wrapper">
            @for (number of offersSkeletonArr; track number) {
                <div class="sk-promohub-tile">
                    <div class="sk-tile-image"></div>
                    <div class="sk-title" style="width: 100%"></div>
                    <div class="sk-title" style="width: 80%"></div>
                </div>
            }
        </div>
    </div>
} @else {
    <div class="bg-home-skeleton sk-promohub-container" [ngClass]="{ 'bg-dark-skeleton': offerContentConfiguration?.enableDarkSkeleton }">
        @for (number of offersSkeletonV2Arr; track number) {
            <div>
                @if (showTitleSkeleton) {
                    <div class="sk-title-wrapper">
                        <div class="sk-circle"></div>
                        <div class="sk-info-wrap">
                            <div class="sk-title"></div>
                            <div class="sk-title"></div>
                        </div>
                    </div>
                }
                <div class="sk-tiles-wrapper">
                    @for (number of [0, 1, 2, 3]; track number) {
                        <div class="sk-promohub-tile">
                            <div class="sk-tile-container">
                                <div class="sk-tile-image"></div>
                                <div class="sk-tile-info">
                                    <div class="sk-title"></div>
                                    <div class="sk-title"></div>
                                    <div class="sk-title"></div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}
