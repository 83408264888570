import { LeaderboardRankingRequest } from '../../common/models/ranking/leaderboard-ranking-request';
import { CardRushContent } from './card-rush-content';
import { CardRushMetaData } from './card-rush-metadata';

export class CardRushDetails {
    cardRushMetaData: CardRushMetaData;
    cardRushContent: CardRushContent;
}

export class CardRushRequest extends LeaderboardRankingRequest {
    cmsItemIdentifier: string;
    isAutoOptin: boolean;

    constructor(promoId: string, requestType: string, isAutoOptin: boolean = false) {
        super();
        this.promoId = promoId;
        this.requestType = requestType;
        this.isAutoOptin = isAutoOptin;
    }
}
