<user-compliance-message></user-compliance-message>
<ng-container *ngrxLet="nLRegulatoryHelperService.displayPromoDetails$ as displayPromoDetails">
    @if (displayPromoDetails) {
        <ng-container *ngrxLet="vm$ as vm">
            @if (vm) {
                <div class="offers-home-page">
                    @if (vm.showSkeleton) {
                        <offers-skeleton [showTeaserSkeleton]="showTeaserSkeleton" [productsLength]="products?.length"> </offers-skeleton>
                    } @else {
                        <ng-content></ng-content>
                        @if (vm.isLobbyEmpty) {
                            <no-offer-message></no-offer-message>
                        }
                        @if (!vm.isLobbyEmpty) {
                            <div class="offers-teaser-groups has-slider wrapper-height">
                                @for (product of vm.offerProducts; track trackByKey(product); let i = $index) {
                                    @if (product) {
                                        <offers-teaser-group
                                            [offerDetailsList]="vm.offerDetailsList"
                                            [productIndex]="i"
                                            [productsLength]="vm.offerProducts.length"
                                            [offerCategory]="vm.offerCategories[product]"
                                            [product]="product"
                                            [showLhSlider]="vm.showLhSlider"
                                            [showTeaserGroupTitle]="true"
                                            [showSeeAllLink]="vm.showSeeAllLink"
                                            [offersProductStyle]="offersProductStyle"
                                            [isOffersLoadedFromDiffApp]="isOffersLoadedFromDiffApp"
                                            [seeAllLinkItemLength]="vm.seeAllLinkItemLength"
                                            class="offers-teaser-group-wrapper items-3">
                                        </offers-teaser-group>
                                    }
                                }
                            </div>
                        }
                    }
                </div>
            }
        </ng-container>
    }
</ng-container>
