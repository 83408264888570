import { createAction, props } from '@ngrx/store';

import { ResultsContent } from '../../common/models/results/results-content';
import { RewardContent } from '../../common/models/reward-content';
import { CardRushDetails, CardRushRequest } from '../card-rush-models/card-rush-details';
import { CardRushCriteriaTitle } from '../card-rush-models/card-rush-state-model';

export const cardRushInit = createAction('[Card Rush] Init');
export const cardRushDestroyed = createAction('[Card Rush] Destroyed');
export const getCardRushData = createAction('[Card Rush] Get Details', props<CardRushRequest>());
export const loadCardRushState = createAction('[Card Rush] Load Details', props<CardRushCriteriaTitle>());
export const loadLeaderBoardState = createAction(
    '[Card Rush] Load LeaderBoard Details',
    props<{ leaderboardResultsContent: ResultsContent; leaderboardRewardsContent: RewardContent }>(),
);
export const loadCardRushSuccess = createAction('[Card Rush] Load CardRush Success', props<CardRushDetails>());

export const optInSuccess = createAction('[Card Rush] Load Details Optin Success', props<CardRushDetails>());
export const cardRushReveal = createAction('[Card Rush] Card Rush Reveal', props<CardRushDetails>());
