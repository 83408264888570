@if (promoClientConfigLoaded) {
    <div class="bet-station-offer-container">
        <div class="betstation-offers">
            <ng-scrollbar viewClass="scroll-container__viewport" class="scroll-container__scroller ng-scrollbar">
                @if (offersAreLoading) {
                    <offers-skeleton></offers-skeleton>
                } @else {
                    <div class="bonuses-home-page offer-teaser-items-nocarousel">
                        @for (offer of offerDetailsList; track offer; let i = $index) {
                            <bet-station-offer-tile-teaser class="offers-teaser-wrapper" [offer]="offer" [teaserNumber]="i">
                            </bet-station-offer-tile-teaser>
                        }
                    </div>
                }
            </ng-scrollbar>
        </div>
    </div>
}
