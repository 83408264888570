import { OverlayRef } from '@angular/cdk/overlay';
import { NgClass, NgStyle } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { GENERIC_MODAL_CONTENT, TrackingModelService } from '@frontend/promo-homewidget';
import { DeviceService, DynamicHtmlDirective, PlainLinkComponent, ViewTemplate } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { EdsClaimRequest } from '../../eds-models/eds-claim-request';
import { EdsContent, Status } from '../../eds-models/eds-content';
import { EdsEventData } from '../../eds-models/eds-event-data';
import { EdsRequest } from '../../eds-models/eds-request';
import { PlayerReward } from '../../eds-models/player-reward';
import { EdsService } from '../../eds-service/eds.service';
import { loadEdsData } from '../../store/eds.action';

@Component({
    selector: 'eds-multi-reward-popup',
    templateUrl: './eds-multi-reward-popup.component.html',
    standalone: true,
    imports: [PlainLinkComponent, NgStyle, DynamicHtmlDirective, NgClass],
})
export class EdsMultiRewardPopupComponent implements OnInit, OnDestroy {
    clientContent: ViewTemplate;
    selectedReward: PlayerReward;
    isButtonDisabled: boolean = true;
    rewardTnc: string;
    isDesktop: boolean;
    selectedRewardIndex: number;
    onClaimRewardFailure: boolean = false;
    onClaimRewardFailureMessage: string;
    eventData: EdsEventData;
    edsContent: EdsContent;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private edsService: EdsService,
        private store: Store,
        private deviceService: DeviceService,
        private trackingModelService: TrackingModelService,
        @Inject(GENERIC_MODAL_CONTENT) public data: any,
        private overlayRef: OverlayRef,
    ) {}

    ngOnInit(): void {
        this.isDesktop = !this.deviceService.isMobile;
        this.getRewardDetails();
        this.webTrackingForListOfRewards();
    }

    webTrackingForListOfRewards() {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'Promo Hub',
            LabelEvent: 'player activity promotions eds',
            ActionEvent: 'load',
            PositionEvent: this.eventData?.eventCriteriaInfo,
            LocationEvent: 'myprogress',
            EventDetails: 'select reward popup',
            URLClicked: 'not applicable',
        });
    }

    getRewardDetails() {
        if (this.data) {
            this.clientContent = this.data.clientContent;
            this.eventData = this.data.eventData;
            this.eventData?.playerRewards?.forEach((x) => (x.isRewardSelected = false));
            this.rewardTnc = this.eventData?.playerRewards[0]?.rewardTnc;
            this.edsContent = this.data?.edsContent;
        }
    }

    chooseReward(reward: PlayerReward, rewardIndex: number) {
        this.webTrackingForChooseAndSelect(reward, rewardIndex + 1, 'choose cta');
        if (reward) {
            this.eventData?.playerRewards?.forEach((item) => {
                if (reward.rewardId === item.rewardId) {
                    reward.isRewardSelected = true;
                    this.isButtonDisabled = false;
                    this.rewardTnc = reward.rewardTnc;
                    this.selectedReward = item;
                    this.selectedRewardIndex = rewardIndex;
                } else {
                    item.isRewardSelected = false;
                }
            });
        }
    }

    webTrackingForChooseAndSelect(reward: PlayerReward, rewardIndex: number, eventDetails: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'Promo Hub',
            LabelEvent: 'player activity promotions eds',
            ActionEvent: 'click',
            PositionEvent: reward?.rewardListDescription + '-' + rewardIndex,
            LocationEvent: 'select rewards popup',
            EventDetails: eventDetails,
            URLClicked: 'not applicable',
        });
    }

    onClickSelect() {
        const request: EdsClaimRequest = {
            rewardId: this.selectedReward?.rewardId?.toString(),
            eventId: this.data?.eventData?.eventId,
            edsId: this.data?.edsId,
        };
        this.webTrackingForChooseAndSelect(this.selectedReward, this.selectedRewardIndex + 1, 'Select Reward cta');
        this.edsService
            .claimEdsReward(request)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (res) => {
                    if (res?.status == 0) {
                        this.store.dispatch(loadEdsData(new EdsRequest(this.data?.edsId, 'overview')));
                        if (res?.totalRewardsClaimed == res?.maxRewardChoice) {
                            this.overlayRef.detach();
                        } else {
                            this.isButtonDisabled = true;
                            this.eventData?.playerRewards?.forEach((item) => {
                                if (item.rewardId == this.selectedReward.rewardId) {
                                    item.rewardStatus = Status[Status.Claimed];
                                } else {
                                    item.isRewardSelected = false;
                                }
                            });
                        }
                    } else if (res?.status == 2) {
                        this.onClaimRewardFailure = true;
                        this.onClaimRewardFailureMessage = this.clientContent?.messages?.alreadyclaimed ?? '';
                    }
                },
                error: () => {
                    this.onClaimRewardFailure = true;
                    this.onClaimRewardFailureMessage = this.clientContent?.messages?.claimfailed ?? '';
                },
            });
    }

    onClosePopup() {
        this.selectedReward = {} as PlayerReward;
        this.overlayRef.detach();
    }

    onCloseErrorBanner() {
        this.onClaimRewardFailure = false;
    }

    webTrackingForCloseAndNotNow(actionEvent: string, eventDetails: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'Promo Hub',
            LabelEvent: 'player activity promotions eds',
            ActionEvent: actionEvent,
            PositionEvent: 'not applicable',
            LocationEvent: 'select rewards popup',
            EventDetails: eventDetails,
            URLClicked: 'not applicable',
        });
    }

    ngOnDestroy() {
        this.overlayRef.detach();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
